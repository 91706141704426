import { TableCell, TableRow } from "@mui/material";
import React from "react";

type DetailsRowProps = {
  label: string;
  value: string | number;
};

const DetailsRow = ({ label, value }: DetailsRowProps) => {
  return (
    <TableRow key={label}>
      <TableCell>{label}</TableCell>
      <TableCell>{value}</TableCell>
    </TableRow>
  );
};

export default DetailsRow;
