import React, { useContext, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { JobDetails } from "../../components/jobadded/JobDetails";
import schoolApiClient from "../../services/SchoolApiClient";
import { RequestedJob } from "../../model/ModelTypes";
import FindingWorkerSpinner from "../../components/jobs/FindingWorkerSpinner";
import { JobContext } from "../../context/JobContext";
import CancelJobCard from "../../components/jobs/job-details/CancelJobCard";
import JobStatus from "../../components/jobs/job-details/JobStatus";
import { getWorkerSelectorStatus, WorkerSelectorStatusEnum } from "../../components/jobs/WorkerSelectorStatusHelper";

const JobDetailsPage = () => {
  const [searchParams] = useSearchParams();
  const jobId = searchParams.get("jobId");

  const { requestedJob, setRequestedJob } = useContext(JobContext);

  useEffect(() => {
    if (!requestedJob) {
      schoolApiClient
        .get(`/jobs/${jobId}/ranked`)
        .then((response) => {
          let requestedJob = response.data as RequestedJob;
          setRequestedJob(requestedJob);
        })
        .catch(() => {
          console.error("Failed to get job");
          alert("Failed to get job");
        });
    }
  }, [jobId, requestedJob, setRequestedJob]);

  if (!requestedJob) {
    return <FindingWorkerSpinner />;
  }

  const job = requestedJob.job;

  const workerSelectorStatus = getWorkerSelectorStatus(job);

  return (
    <>
      <JobStatus />

      <JobDetails job={job} />

      {(workerSelectorStatus === WorkerSelectorStatusEnum.WORKER || workerSelectorStatus === WorkerSelectorStatusEnum.NOTIFIED) && <CancelJobCard job={job} />}
    </>
  );
};

export default JobDetailsPage;
