import * as React from "react";
import { TableCell } from "@mui/material";

export type UpcomingPlinysCellProps = Readonly<{
  id?: string;
  children: React.ReactNode;
}>;

function UpcomingPlinysCell({ id, children }: UpcomingPlinysCellProps) {
  return (
    <TableCell id={id} align="center" sx={{ justifyContent: "center", margin: "auto", textAlign: "center" }}>
      {children}
    </TableCell>
  );
}

export default UpcomingPlinysCell;
