export const primaryColor = "#a98ff7";
export const darkPrimaryColor = "#9C7eF6";

export const secondaryColor = "#338CC8";

export const disabledColor = "#888";

export const mainBackgroundColor = "#F8F8F8";
export const whiteColor = "#FFF";

export const goldColor = "#FFD700";
export const silverColor = "#C0C0C0";
export const bronzeColor = "#CD7F32";

export const successBackgroundColor = "#E0FFE0";
export const errorBackgroundColor = "#FFE0E0";
export const warningBackgroundColor = "#FFFFE0";
export const infoBackgroundColor = "#E0EEFF";
export const disabledBackgroundColor = "#EEE";
