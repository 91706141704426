import { Worker } from "../../model/ModelTypes";
import { Link } from "react-router-dom";
import * as React from "react";
import ThemedButton from "../buttons/ThemedButton";
import PersonIcon from "@mui/icons-material/Person";

type PlinyDetailsLinkTextProps = Readonly<{
  worker: Worker;
  jobId?: number;
  title: string;
}>;

export default function PlinyDetailsLinkButton({ worker, jobId, title }: PlinyDetailsLinkTextProps) {
  return (
    <Link id={jobId ? "job-" + jobId + "-worker-link" : "worker-link"} to={{ pathname: `/pliny?workerId=${worker.workerId}` }}>
      <ThemedButton title={title} style={{ marginTop: 0 }} endIcon={<PersonIcon />} color={"success"} />
    </Link>
  );
}
