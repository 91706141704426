import * as React from "react";
import School from "./School";
import SchoolSettings from "./SchoolSettings";

export default function SettingsPage() {
  return (
    <>
      <SchoolSettings />
      <School />
    </>
  );
}
