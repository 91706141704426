import SimpleCard from "../../SimpleCard";
import { Typography } from "@mui/material";
import DragAndDropList from "./DragAndDropList";

const RankedWorkersCard = () => (
  <SimpleCard title={"Your top Pliny candidates"}>
    <Typography>We've found the following Plinys for you. Have a look at their details and re-arrange them into the order you wish to notify them.</Typography>
    <DragAndDropList />
  </SimpleCard>
);

export default RankedWorkersCard;
