import * as React from "react";
import { useContext, useState } from "react";
import SimpleCard from "../../SimpleCard";
import { Button, CircularProgress, Typography } from "@mui/material";
import { Job } from "../../../model/ModelTypes";
import schoolApiClient from "../../../services/SchoolApiClient";
import { JobContext } from "../../../context/JobContext";
import ThemedDialog from "../../dialog/ThemedDialog";

type RequestWorkerBaseFormProps = {
  job: Job;
};

const CancelJobCard = ({ job }: RequestWorkerBaseFormProps) => {
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { requestedJob, setRequestedJob } = useContext(JobContext);

  const handleSubmit = async () => {
    setIsLoading(true);
    const requestParams = {
      cancelJob: true,
    };

    await schoolApiClient
      .patch(`/jobs/${job.jobId}`, requestParams)
      .then((response) => {
        let job = response.data as Job;
        setRequestedJob({ ...requestedJob, job });
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Job failed to cancel");
        console.error(error);
        alert("Sorry that Job failed to cancel");
        setIsLoading(false);
      });
  };

  // cannot cancel job if it is after 7:30am on the start date of the job
  const cannotCancelJob = () => {
    const startDate = new Date(job.startDate);
    const currentDate = new Date();
    const currentHour = currentDate.getHours();
    const currentMinute = currentDate.getMinutes();
    return currentDate.getDate() === startDate.getDate() && currentHour >= 7 && currentMinute >= 30;
  };

  return (
    <SimpleCard title={"Cancel Job"}>
      <Typography>If it is before 7:30am on the start date of the job then you can cancel below.</Typography>

      {isLoading && <CircularProgress size={50} />}

      {!isLoading && (
        <>
          <Button variant="contained" color={"error"} onClick={() => setOpen(true)} disabled={cannotCancelJob()}>
            Cancel Job
          </Button>
          <ThemedDialog title={"Cancel Job"} content={"Are you sure you want to cancel this job?"} handleSubmit={handleSubmit} open={open} setOpen={setOpen} />
        </>
      )}
    </SimpleCard>
  );
};

export default CancelJobCard;
