import { Role, RoleType, Worker } from "../../../model/ModelTypes";

export const getRole = (worker: Worker, roleType: RoleType): Role => {
  const role = worker?.roles?.find((role) => role.roleType === roleType);
  if (!role) {
    throw new Error(`Role not found for roleType: ${roleType}`);
  }
  return role;
};

export const getRoleField = (worker: Worker, roleType: RoleType, field: keyof Role): any => {
  const role = getRole(worker, roleType);
  return role[field];
};

export const getField = (role: any, field: any): any => {
  return role[field];
};
