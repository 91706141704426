import { SecondaryTeacherJob } from "../../../model/ModelTypes";
import DetailsRow from "../../tables/DetailsRow";

type SecondaryTeacherJobDetailsProps = {
  job: SecondaryTeacherJob;
};

const SecondaryTeacherJobDetails = ({ job }: SecondaryTeacherJobDetailsProps) => {
  return <DetailsRow label={"Subject"} value={job.subject.name} />;
};

export default SecondaryTeacherJobDetails;
