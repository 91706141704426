import SimpleCard from "../SimpleCard";
import { CircularProgress, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Job } from "../../model/ModelTypes";
import { WorkersNotifiedList } from "./workersnotifiedlist/WorkersNotifiedList";
import { WorkersNotified } from "./workersnotifiedlist/types";
import schoolApiClient from "../../services/SchoolApiClient";
import { JobContext } from "../../context/JobContext";
import { POLLING_FREQUENCY } from "../../constants/PlinyConstants";

export const notifiedWorkerIconSize = 30;

export const WorkerRequestProcessing = (props: { job: Job }) => {
  const { requestedJob, setRequestedJob } = useContext(JobContext);

  const [workerSelectorState, setWorkerSelectorState] = useState<WorkersNotified>();

  useEffect(() => {
    const fetchData = () => {
      schoolApiClient
        .get(`/jobs/${requestedJob.job.jobId}`)
        .then((response) => {
          const job = response.data as Job;
          setRequestedJob({ ...requestedJob, job });
        })
        .catch((error) => {
          console.error("Failed to get job");
          alert("Failed to get job");
        });
    };

    fetchData();

    const intervalId = setInterval(fetchData, POLLING_FREQUENCY);

    return () => clearInterval(intervalId);
  }, []);

  //TODO show when worker rejects job

  return (
    <SimpleCard title="Finding Pliny">
      <Typography>We are currently searching for a Pliny for you:</Typography>
      <WorkersNotifiedList workerSelectorStateMessage={workerSelectorState} job={props.job} />
      <CircularProgress size={50} />
    </SimpleCard>
  );
};
