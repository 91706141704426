import React from "react";
import SecondaryTeacherCard from "./SecondaryTeacherCard";
import PrimaryTeacherCard from "./PrimaryTeacherCard";
import { Card } from "@mui/material";
import { Role, RoleType } from "../../../model/ModelTypes";

type RoleCardProps = {
  role: Role;
};

const RoleCard = ({ role }: RoleCardProps) => {
  if (role.roleType === RoleType.PRIMARY_TEACHER) {
    return <PrimaryTeacherCard primaryTeacher={role} />;
  }
  if (role.roleType === RoleType.SECONDARY_TEACHER) {
    return <SecondaryTeacherCard secondaryTeacher={role} />;
  }
  return <Card title={"Unidentified role"}>Unidentified role: {JSON.stringify(role)}</Card>;
};

export default RoleCard;
