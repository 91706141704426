import SimpleCard from "../../components/SimpleCard";
import { TextField, Typography } from "@mui/material";
import * as React from "react";
import { useContext, useEffect, useState } from "react";
import useInput from "../../hooks/Input";
import { UserContext } from "../../context/UserContext";
import SubmitButton from "../../components/buttons/SubmitButton";
import theme from "../../constants/Theme";
import schoolApiClient from "../../services/SchoolApiClient";

export default function School() {
  const [showSuccessMessage, setShowSuccessMessage] = useState<boolean>(false);

  const isPhoneNumber = (value: string) => /^[+]?\d{6,}$/.test(value);

  const isEmailAddress = (value: string) =>
    /^(([^<>()[ó\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value);

  const isPostcode = (value: string) =>
    /([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z]\d{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y]\d{1,2})|(([A-Za-z]\d[A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y]\d[A-Za-z]?))))\s?\d[A-Za-z]{2})/.test(
      value
    );

  const userContext: any = useContext(UserContext);

  useEffect(() => {
    if (userContext.admin) {
      name_changeValueIfUntouched(userContext.admin.school.name);
      phoneNumber_changeValueIfUntouched(userContext.admin.school.phoneNumber);
      email_changeValueIfUntouched(userContext.admin.school.email);
      addressLine1_changeValueIfUntouched(userContext.admin.school.addressLine1);
      addressLine2_changeValueIfUntouched(userContext.admin.school.addressLine2);
      townCity_changeValueIfUntouched(userContext.admin.school.townCity);
      postcode_changeValueIfUntouched(userContext.admin.school.postcode);
    }
  }, [userContext.admin]); // eslint-disable-line react-hooks/exhaustive-deps

  const {
    value: name,
    isValid: name_isValid,
    displayError: name_displayError,
    valueChangeHandler: name_changeHandler,
    inputBlurHandler: name_blurHandler,
    changeValueIfUntouched: name_changeValueIfUntouched,
  } = useInput((value) => typeof value === "string" && value.length > 0);

  const {
    value: phoneNumber,
    isValid: phoneNumber_isValid,
    displayError: phoneNumber_displayError,
    valueChangeHandler: phoneNumber_changeHandler,
    inputBlurHandler: phoneNumber_blurHandler,
    changeValueIfUntouched: phoneNumber_changeValueIfUntouched,
  } = useInput((value) => typeof value === "string" && isPhoneNumber(value));

  const {
    value: email,
    isValid: email_isValid,
    displayError: email_displayError,
    valueChangeHandler: email_changeHandler,
    inputBlurHandler: email_blurHandler,
    changeValueIfUntouched: email_changeValueIfUntouched,
  } = useInput((value) => typeof value === "string" && isEmailAddress(value));

  const {
    value: addressLine1,
    isValid: addressLine1_isValid,
    displayError: addressLine1_displayError,
    valueChangeHandler: addressLine1_changeHandler,
    inputBlurHandler: addressLine1_blurHandler,
    changeValueIfUntouched: addressLine1_changeValueIfUntouched,
  } = useInput((value) => typeof value === "string" && value.length > 0);

  const {
    value: addressLine2,
    valueChangeHandler: addressLine2_changeHandler,
    changeValueIfUntouched: addressLine2_changeValueIfUntouched,
  } = useInput(() => true);

  const {
    value: townCity,
    isValid: townCity_isValid,
    displayError: townCity_displayError,
    valueChangeHandler: townCity_changeHandler,
    inputBlurHandler: townCity_blurHandler,
    changeValueIfUntouched: townCity_changeValueIfUntouched,
  } = useInput((value) => typeof value === "string" && value.length > 0);

  const {
    value: postcode,
    isValid: postcode_isValid,
    displayError: postcode_displayError,
    valueChangeHandler: postcode_changeHandler,
    inputBlurHandler: postcode_blurHandler,
    changeValueIfUntouched: postcode_changeValueIfUntouched,
  } = useInput((value) => typeof value === "string" && isPostcode(value));

  const formIsValid = name_isValid && phoneNumber_isValid && email_isValid && addressLine1_isValid && townCity_isValid && postcode_isValid;

  const formSubmitHandler = (event: any) => {
    event.preventDefault();

    if (formIsValid) {
      const requestParams = {
        schoolId: userContext.admin.school.schoolId, //TODO userContext.school.schoolId maybe?

        name,
        phoneNumber,
        email,

        addressLine1,
        addressLine2,
        townCity,
        postcode,
      };

      schoolApiClient.patch("", requestParams).then((response) => {
        console.log(response);
        setShowSuccessMessage(true);
        userContext.admin.school = response.data;
      });
    }
  };

  return (
    <SimpleCard title={"School Details"}>
      <div>
        <TextField
          id={"SchoolName"}
          label={"School Name"}
          value={name}
          onChange={(event) => {
            name_changeHandler(event);
            setShowSuccessMessage(false);
          }}
          onBlur={name_blurHandler}
          error={name_displayError}
          helperText={name_displayError ? "Please enter a school name" : ""}
        />
      </div>
      <div>
        <TextField
          label={"School Phone Number"}
          value={phoneNumber}
          onChange={(event) => {
            phoneNumber_changeHandler(event);
            setShowSuccessMessage(false);
          }}
          onBlur={phoneNumber_blurHandler}
          error={phoneNumber_displayError}
          helperText={phoneNumber_displayError ? "Please enter a valid phone number" : ""}
        />
        <TextField
          label={"Email Address"}
          value={email}
          onChange={(event) => {
            email_changeHandler(event);
            setShowSuccessMessage(false);
          }}
          onBlur={email_blurHandler}
          error={email_displayError}
          helperText={email_displayError ? "Please enter a valid email address" : ""}
        />
      </div>
      <div>
        <TextField
          label={"Address Line 1"}
          value={addressLine1}
          onChange={(event) => {
            addressLine1_changeHandler(event);
            setShowSuccessMessage(false);
          }}
          onBlur={addressLine1_blurHandler}
          error={addressLine1_displayError}
          helperText={addressLine1_displayError ? "Please enter a valid address" : ""}
        />
      </div>
      <div>
        <TextField
          label={"Address Line 2"}
          value={addressLine2}
          onChange={(event) => {
            addressLine2_changeHandler(event);
            setShowSuccessMessage(false);
          }}
        />
      </div>
      <div>
        <TextField
          label={"Town/City"}
          value={townCity}
          onChange={(event) => {
            townCity_changeHandler(event);
            setShowSuccessMessage(false);
          }}
          onBlur={townCity_blurHandler}
          error={townCity_displayError}
          helperText={townCity_displayError ? "Please enter a town or city" : ""}
        />
        <TextField
          label={"Postcode"}
          value={postcode}
          onChange={(event) => {
            postcode_changeHandler(event);
            setShowSuccessMessage(false);
          }}
          onBlur={postcode_blurHandler}
          error={postcode_displayError}
          helperText={postcode_displayError ? "Please enter a valid postcode" : ""}
        />
      </div>

      {showSuccessMessage && <Typography color={theme.palette.success.main}>Details successfully saved!</Typography>}

      <SubmitButton id={"SaveSchool"} onClickHandler={formSubmitHandler} isFormValid={formIsValid && !showSuccessMessage} />
    </SimpleCard>
  );
}
