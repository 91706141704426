import { Grid } from "@mui/material";
import * as React from "react";
import SimpleCard from "../SimpleCard";
import { PageLoadingSpinner } from "../nav/PageLoadingSpinner";

export const FindingWorkerSpinner = () => (
  <SimpleCard title={"Searching for your best Plinys!"}>
    <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center" style={{ minHeight: "40vh", marginBottom: 20 }}>
      <PageLoadingSpinner size={100} />
    </Grid>
  </SimpleCard>
);

export default FindingWorkerSpinner;
