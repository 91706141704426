import React, { useState } from "react";
import { awsPublicClient } from "../services/AwsPublicClient";
import { Subject } from "../model/ModelTypes";

export type IDefaultSubjects = {
  code: string;
  name: string;
};

export const AppContext = React.createContext({
  defaultSubjects: [] as IDefaultSubjects[],
  setDefaultSubjects: (admin: any) => {
    // Empty as function gets populated in provider
  },
  populateDefaultSubjects: () => {
    // Empty as function gets populated in provider
  },
});

export function AppContextProvider(props: { children: JSX.Element }) {
  const [defaultSubjects, setDefaultSubjects] = useState<any>();

  const populateDefaultSubjects = async () => {
    awsPublicClient
      .get("/api/public/getAllSubjects")
      .then((response) => {
        const subjectDetails: Subject[] = response.data;
        if (subjectDetails) {
          setDefaultSubjects(sortSubjectsByName(subjectDetails));
        } else {
          console.log("Could not get default subjects.");
        }
      })
      .catch((error) => {
        console.log("Error getting default subjects.");
      });
  };

  // TODO-SONAR
  const context = {
    defaultSubjects: defaultSubjects,
    setDefaultSubjects: setDefaultSubjects,
    populateDefaultSubjects: populateDefaultSubjects,
  };

  return <AppContext.Provider value={context}>{props.children}</AppContext.Provider>;
}

function sortSubjectsByName(subjectDetails: Subject[]) {
  return subjectDetails.sort((a: Subject, b: Subject) => {
    if (a.name > b.name) {
      return 1;
    }
    return -1;
  });
}
