import React, { useMemo, useState } from "react";
import adminApiClient from "../services/AdminApiClient";
import { Admin } from "../model/ModelTypes";

export const UserContext = React.createContext({
  admin: {} as Admin,
  setAdmin: (admin: Admin | null) => {
    // Empty as function gets populated in provider
  },
  getAdminDetails: () => {
    // Empty as function gets populated in provider
  },
});

export function UserContextProvider(props: { children: JSX.Element }) {
  const [admin, setAdmin] = useState<Admin>();

  const getAdminDetails = async () => {
    const response = await adminApiClient.get("");

    const adminDetails = await response.data;

    setAdmin(adminDetails);
  };

  const context = useMemo(() => {
    return {
      admin: admin,
      setAdmin: setAdmin,
      getAdminDetails: getAdminDetails,
    };
  }, [admin]);

  return <UserContext.Provider value={context}>{props.children}</UserContext.Provider>;
}
