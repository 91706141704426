import { CircularProgress, Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useContext, useEffect, useState } from "react";
import ReviewPlinyCard from "../../components/reviews/ReviewPlinyCard";
import SimpleCard from "../../components/SimpleCard";
import { UserContext } from "../../context/UserContext";
import { Job } from "../../model/ModelTypes";
import schoolApiClient from "../../services/SchoolApiClient";

const ReviewPlinysPage = () => {
  const [jobs, setJobs] = useState<Job[]>();
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [error, setError] = useState<string>("");

  const userContext: any = useContext(UserContext);

  useEffect(() => {
    const schoolId = userContext?.admin?.school?.schoolId;

    const getJobsForReview = async () => {
      schoolApiClient.get(`/workers/reviews`).then(
        (response) => {
          let jobList = response.data;
          if (jobList) {
            setIsLoaded(true);
            setJobs(jobList);
          } else {
            setIsLoaded(true);
            setError("You are currently up to date with all reviews.");
          }
        },
        (err) => {
          setIsLoaded(true);
          setError(err);
        }
      );
    };
    if (schoolId) {
      getJobsForReview();
    }
  }, [userContext?.admin?.school?.schoolId]);

  if (isLoaded) {
    if (error === "" && jobs) {
      return (
        <div className="w-full overflow-auto">
          {jobs.length > 0 ? (
            jobs.map((job) => {
              return (
                job?.worker && <ReviewPlinyCard job={job} key={job.jobId} /> // Don't want to review a job if no worker has been assigned to it
              );
            })
          ) : (
            <NoReviewsRequired />
          )}
        </div>
      );
    } else {
      return <NoReviewsRequired />;
    }
  } else {
    return (
      <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center" style={{ minHeight: "40vh" }}>
        <CircularProgress />
      </Grid>
    );
  }
};

const NoReviewsRequired = () => {
  return (
    <SimpleCard title={"No Reviews Required"}>
      <Typography>You are currently up to date with all reviews.</Typography>
    </SimpleCard>
  );
};

export default ReviewPlinysPage;
