import { WorkerProfileForSchools } from "../../../model/ModelTypes";
import React, { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import { getRandomInt } from "../../../helpers/MathsHelper";

type RollingReviewProps = {
  workerProfile: WorkerProfileForSchools;
};

const ROLLING_REVIEW_INTERVAL = 5_000;

const RollingReview = ({ workerProfile }: RollingReviewProps) => {
  const reviewsLength = workerProfile?.reviews?.length;
  const [reviewIndex, setReviewIndex] = useState<number>(0);

  useEffect(() => {
    const interval = setInterval(() => setReviewIndex(getRandomInt(reviewsLength)), ROLLING_REVIEW_INTERVAL);
    return () => {
      clearInterval(interval);
    };
  }, [reviewIndex]);

  if (workerProfile?.reviews.length > 0) {
    return (
      <>
        <Typography variant={"h6"}>Previous reviews:</Typography>
        <Typography>{workerProfile?.reviews[reviewIndex]?.review}</Typography>
      </>
    );
  }
  return null;
};

export default RollingReview;
