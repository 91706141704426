import { Typography } from "@mui/material";
import React from "react";

export const SimpleCardTitle = (props: { title: string }) => {
  return (
    <Typography variant="h5" className={"Typography"} id={props.title}>
      {props.title}
    </Typography>
  );
};
