import { Auth } from "aws-amplify";
import { GetObjectCommand, S3Client } from "@aws-sdk/client-s3";
import { getSignedUrl } from "@aws-sdk/s3-request-presigner";
import { School, Worker } from "../model/ModelTypes";

const REGION = "eu-west-2";

type BucketSuffix = "docs" | "images";

export async function getSignedS3ObjectUrl(bucketSuffix: BucketSuffix, objectKey: string): Promise<string> {
  const credentials = await Auth.currentCredentials();

  const bucketName = `${process.env.REACT_APP_BUCKET_PREFIX}-${bucketSuffix}`;

  const client = new S3Client({ region: REGION, credentials: credentials });

  const getObjectCommand = new GetObjectCommand({
    Bucket: bucketName,
    Key: objectKey,
  });

  return await getSignedUrl(client, getObjectCommand, { expiresIn: 3_600 });
}

export const getWorkerCvKey = (worker: Worker) => `workers/${worker.workerId}/${worker.workerAppInfo?.cvFileName}`;

export const getWorkerProfilePictureKey = (worker: Worker) => `workers/${worker.workerId}/${worker.workerAppInfo?.profilePictureFileName}`;

export const getSchoolProfilePictureKey = (school: School) => `schools/${school.schoolId}/${school.schoolAppInfo?.profilePictureFileName}`;
