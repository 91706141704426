import { TableCell, TableRow } from "@mui/material";
import React from "react";

type PlinyDetailsTableRowProps = {
  label: string;
  value: string | undefined;
};

const PlinyDetailsTableRow = ({ label, value }: PlinyDetailsTableRowProps) => (
  <TableRow key={`${label}-row`}>
    <TableCell className="px-0 capitalize" align="left" id={`${label}-label`}>
      {label}
    </TableCell>
    <TableCell className="px-0 capitalize" align="left" id={`${label}-value`}>
      {value}
    </TableCell>
  </TableRow>
);

export default PlinyDetailsTableRow;
