import axiosClient from "axios";
import { getBaseUrl, setupHeaders, setupUrlPath } from "./AwsClientHelper";

export const adminApiClient = axiosClient.create({
  baseURL: getBaseUrl(),
});

adminApiClient.interceptors.request.use(
  async (config) => {
    try {
      config = await setupHeaders(config);
      config = await setupUrlPath(config, "/api/admins", "sub");
    } catch (err) {
      console.log(err);
    }
    return config;
  },

  (error) => {
    return Promise.reject(error);
  }
);

export default adminApiClient;
