import React from "react";
import UpcomingPlinysTable from "../../components/jobs/UpcomingPlinysTable";
import { Paper, Typography } from "@mui/material";
import SimpleCard from "../../components/SimpleCard";
import { useIsSmallScreen } from "../../hooks/IsSmallScreen";

const UpcomingPlinysPage = () => {
  const isSmallScreen = useIsSmallScreen();

  if (isSmallScreen) {
    return (
      <Paper sx={{ margin: "-24px", backgroundColor: "white" }}>
        <Typography variant="h5" className={"Typography"} sx={{ padding: "20px" }}>
          Upcoming Plinys
        </Typography>
        <UpcomingPlinysTable />
      </Paper>
    );
  }
  return (
    <SimpleCard title="Upcoming Plinys">
      <UpcomingPlinysTable />
    </SimpleCard>
  );
};

export default UpcomingPlinysPage;
