import * as React from "react";
import { useContext, useEffect } from "react";
import RequestWorkerBaseForm from "./RequestWorkerBaseForm";
import { RoleType } from "../../../model/ModelTypes";
import { Controller } from "react-hook-form";
import { Autocomplete, TextField } from "@mui/material";
import { AppContext } from "../../../context/AppContext";
import useNewJobForm from "./useNewJobForm";
import { UserContext } from "../../../context/UserContext";

export default function RequestSecondaryTeacherForm() {
  const userContext = useContext(UserContext);
  const { defaultSubjects } = useContext(AppContext);

  const { form, errors, control, reset } = useNewJobForm();

  useEffect(() => {
    if (userContext.admin) {
      reset({
        subject: defaultSubjects?.find((subject) => {
          return subject.code === "GEN";
        }),
      });
    }
  }, [defaultSubjects, reset, userContext.admin]);

  return (
    <RequestWorkerBaseForm roleType={RoleType.SECONDARY_TEACHER} form={form}>
      <Controller
        name={"subject"}
        control={control}
        rules={{
          required: true,
        }}
        render={({ field: { onChange, value } }) => (
          <Autocomplete
            id={"subject"}
            options={defaultSubjects}
            getOptionLabel={(option) => option.name}
            value={value || null}
            onChange={(event, item) => {
              onChange(item);
            }}
            isOptionEqualToValue={(option, val) => option.code === val.code}
            renderInput={(params) => (
              <TextField {...params} label="Subject" variant="outlined" error={!!errors.subject} helperText={errors.subject ? "Please choose a subject" : ""} />
            )}
          />
        )}
      />
    </RequestWorkerBaseForm>
  );
}
