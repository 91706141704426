import * as React from "react";
import RequestWorkerBaseForm from "./RequestWorkerBaseForm";
import { PrimaryYear, RoleType } from "../../../model/ModelTypes";
import { Controller } from "react-hook-form";
import { Autocomplete, TextField } from "@mui/material";
import useNewJobForm from "./useNewJobForm";

export default function RequestPrimaryTeacherForm() {
  const { form, errors, control } = useNewJobForm();

  return (
    <RequestWorkerBaseForm roleType={RoleType.PRIMARY_TEACHER} form={form}>
      <Controller
        name={"primaryYear"}
        control={control}
        render={({ field: { onChange, value } }) => (
          <Autocomplete
            id={"primaryYear"}
            options={Object.values(PrimaryYear)}
            getOptionLabel={(option) => option}
            value={value || null}
            onChange={(event, item) => {
              onChange(item);
            }}
            isOptionEqualToValue={(option, val) => option === val}
            renderInput={(params) => (
              <TextField
                {...params}
                label="What year group?"
                variant="outlined"
                error={!!errors.primaryYear}
                helperText={errors.primaryYear ? "Please select a year group" : ""}
              />
            )}
          />
        )}
      />
    </RequestWorkerBaseForm>
  );
}
