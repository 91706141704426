import * as React from "react";
import { useEffect, useState } from "react";
import { getSignedS3ObjectUrl, getWorkerProfilePictureKey } from "../../services/AwsStorageService";
import { Worker } from "../../model/ModelTypes";

type PlinyProfilePictureProps = {
  worker: Worker;
  size?: number;
};

export const PlinyProfilePicture = ({ worker, size = 300 }: PlinyProfilePictureProps) => {
  const { forename, surname } = worker;

  const [image, setImage] = useState<string>();

  useEffect(() => {
    const getS3Image = async () => {
      const result = await getSignedS3ObjectUrl("images", getWorkerProfilePictureKey(worker));
      setImage(result);
    };
    getS3Image();
  }, [worker]);

  return (
    <img
      src={image}
      alt={`Headshot of ${forename} ${surname}`}
      style={{
        width: "100%",
        height: "100%",
        maxWidth: size,
        maxHeight: size,
        borderRadius: "50%",
      }}
    />
  );
};
