import React, { useState } from "react";
import StyledRating from "./Rating.styled";

const RatePliny = (props: { id: string; onChangeValue: (event: any) => void }) => {
  const [rating, setRating] = useState<number | null>(3); // initial rating value

  return (
    <div id={props.id}>
      <p>Pliny Rating:</p>
      <StyledRating
        name="rate-pliny"
        size="large"
        value={rating}
        onChange={(event, newValue) => {
          setRating(newValue);
        }}
      />
    </div>
  );
};

export default RatePliny;
