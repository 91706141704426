import * as React from "react";
import Typography from "@mui/material/Typography";

export const PageLoadError = (props: { error: string }) => (
  <div className="w-full overflow-auto">
    <div className={"text-14"}>
      <Typography>{props.error}</Typography>
    </div>
  </div>
);
