import React, { useContext, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import { WorkerProfileForSchools } from "../../../model/ModelTypes";
import schoolApiClient from "../../../services/SchoolApiClient";
import SubmitButton from "../../buttons/SubmitButton";
import RankedWorkerItem from "./RankedWorkerItem";
import { primaryColor, secondaryColor } from "../../../constants/Colors";
import Box from "@mui/material/Box";
import * as IoIcons from "react-icons/io";
import { JobContext } from "../../../context/JobContext";

const BORDER_RADIUS = 8;

const reorder = (list: WorkerProfileForSchools[], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const grid = 1;

const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
  userSelect: "none",
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,

  background: isDragging ? secondaryColor : primaryColor,

  borderRadius: BORDER_RADIUS,

  ...draggableStyle,
});

const getListStyle = () => ({
  background: primaryColor,
  padding: grid,
  borderRadius: BORDER_RADIUS,
  maxWidth: 1000,
});

const DragAndDropList = () => {
  const { requestedJob, setRequestedJob } = useContext(JobContext);

  const { rankedWorkers, job } = requestedJob;
  const [rankingWorkers, setRankingWorkers] = useState<WorkerProfileForSchools[]>(rankedWorkers);

  const onDragEnd = (result: any) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const reorderedWorkers = reorder(rankingWorkers, result.source.index, result.destination.index);

    setRankingWorkers(reorderedWorkers);
  };

  const removeWorker = (workerId: string) => {
    if (rankingWorkers.length > 1) {
      const updatedWorkers = rankingWorkers.filter((workerProfile) => workerProfile.worker.workerId !== workerId);
      setRankingWorkers(updatedWorkers);
    }
  };

  const formSubmitHandler = async () => {
    const requestParams = rankingWorkers.map((workerProfile) => workerProfile.worker.workerId);

    await schoolApiClient
      .patch(`/jobs/${job.jobId}/ranked`, requestParams)
      .then((response) => {
        setRequestedJob(response.data);
      })
      .catch((error) => {
        console.error("Failed to notify teachers, please contact Pliny");
        console.error(error);
        alert("Failed to notify teachers, please contact Pliny");
      });
  };

  return (
    <Box sx={{ my: 3 }}>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef} style={getListStyle()}>
              {rankingWorkers?.map((workerProfile: WorkerProfileForSchools, index: number) => {
                const worker = workerProfile.worker;

                return (
                  <Draggable key={worker.workerId} draggableId={worker.workerId} index={index}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                      >
                        <RankedWorkerItem job={job} workerProfile={workerProfile} removeWorker={removeWorker} />
                      </div>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <SubmitButton title={"Notify teachers"} color={"secondary"} onClickHandler={formSubmitHandler} isFormValid={true} endIcon={<IoIcons.IoMdSend />} />
    </Box>
  );
};

export default DragAndDropList;
