import { AmplifyProvider, Authenticator, Image, useTheme, View } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import Amplify from "aws-amplify";
import locale from "date-fns/locale/en-GB";
import { useEffect } from "react";
import { amplifyTheme } from "./constants/AmplifyTheme";
import { mainBackgroundColor } from "./constants/Colors";
import { AppContextProvider } from "./context/AppContext";
import { UserContextProvider } from "./context/UserContext";
import AppRoutes from "./routes/AppRoutes";
import amplifyConfigDevelopment from "./aws-exports-dev";
import amplifyConfigProduction from "./aws-exports-prod";

if (process.env.NODE_ENV === "production") {
  Amplify.configure(amplifyConfigProduction);
} else {
  Amplify.configure(amplifyConfigDevelopment);
}

export const drawerWidth = 270;

const authenticatorComponents = {
  Header() {
    const { tokens } = useTheme();

    return (
      <View textAlign="center" padding={tokens.space.large}>
        <Image alt="Pliny logo" src={`pliny-full-title.png`} width={"70%"} />
      </View>
    );
  },
};

function App() {
  useEffect(() => {
    document.body.style.backgroundColor = mainBackgroundColor;
  }, []);

  return (
    <UserContextProvider>
      <AppContextProvider>
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale}>
          <AmplifyProvider theme={amplifyTheme}>
            <Authenticator components={authenticatorComponents} hideSignUp={true} loginMechanisms={["email"]}>
              {({ signOut, user }) => <AppRoutes signOut={signOut} user={user} />}
            </Authenticator>
          </AmplifyProvider>
        </LocalizationProvider>
      </AppContextProvider>
    </UserContextProvider>
  );
}

export default App;
