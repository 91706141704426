import React, { useContext } from "react";
import { Typography } from "@mui/material";
import { JobContext } from "../../../context/JobContext";
import { disabledBackgroundColor, errorBackgroundColor, successBackgroundColor } from "../../../constants/Colors";
import RankedWorkersCard from "../rank-workers/RankedWorkersCard";
import { WorkerRequestProcessing } from "../../jobadded/WorkerRequestProcessing";
import SimpleCard from "../../SimpleCard";
import PlinyAcceptedJobLink from "../../links/PlinyAcceptedJobLink";
import { getWorkerSelectorStatus, WorkerSelectorStatusEnum } from "../WorkerSelectorStatusHelper";

const JobStatus = () => {
  const { requestedJob } = useContext(JobContext);

  const job = requestedJob.job;

  const workerSelectorStatus = getWorkerSelectorStatus(job);

  switch (workerSelectorStatus) {
    case WorkerSelectorStatusEnum.CANCELLED:
      return (
        <>
          <SimpleCard title={"Job Cancelled"} backgroundColor={disabledBackgroundColor}>
            <Typography>This job has been cancelled! No Pliny will be coming.</Typography>
          </SimpleCard>
        </>
      );
    case WorkerSelectorStatusEnum.WORKER:
      return (
        <SimpleCard title="Pliny Accepted" backgroundColor={successBackgroundColor}>
          <Typography>The following Pliny has accepted your job:</Typography>
          <PlinyAcceptedJobLink worker={job.worker} />
        </SimpleCard>
      );
    case WorkerSelectorStatusEnum.REQUESTED:
      return <RankedWorkersCard />;
    case WorkerSelectorStatusEnum.NOTIFIED:
      return <WorkerRequestProcessing job={job} />;
    case WorkerSelectorStatusEnum.NO_PLINYS:
      return (
        <SimpleCard title="Sorry no Plinys were available" backgroundColor={errorBackgroundColor}>
          <Typography>We're really sorry but we don't have any Plinys available for your request.</Typography>
        </SimpleCard>
      );
  }
};

export default JobStatus;
