import { PrimaryTeacherJob } from "../../../model/ModelTypes";
import DetailsRow from "../../tables/DetailsRow";

type PrimaryTeacherJobDetailsProps = {
  job: PrimaryTeacherJob;
};

const PrimaryTeacherJobDetails = ({ job }: PrimaryTeacherJobDetailsProps) => {
  return <DetailsRow label={"Year Group"} value={job.primaryYear} />;
};

export default PrimaryTeacherJobDetails;
