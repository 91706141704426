import SendIcon from "@mui/icons-material/Send";
import { Autocomplete, Checkbox, FormControlLabel, Radio, RadioGroup, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { isAxiosError } from "axios";
import * as React from "react";
import { ReactNode, useContext, useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { UseFormReturn } from "react-hook-form/dist/types";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../../context/UserContext";
import { getTodaysDateAtMidnight } from "../../../helpers/DateFormatter";
import { HalfDay, RequestedJob, RoleType, Worker } from "../../../model/ModelTypes";
import { getJobRoute } from "../../../routes/RouteUrls";
import schoolApiClient from "../../../services/SchoolApiClient";
import SubmitButton from "../../buttons/SubmitButton";
import SimpleCard from "../../SimpleCard";
import FindingWorkerSpinner from "../FindingWorkerSpinner";
import { NewJobInputs } from "../types";

const todaysDate: Date = getTodaysDateAtMidnight();

type RequestWorkerBaseFormProps = {
  roleType: RoleType;
  children: ReactNode;
  form: UseFormReturn<any>;
};

const RequestWorkerBaseForm = ({ roleType, children, form }: RequestWorkerBaseFormProps) => {
  const userContext = useContext(UserContext);

  const navigate = useNavigate();

  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    formState: { errors, isValid },
  } = form;

  const [recentWorkers, setRecentWorkers] = useState<Worker[]>();

  const [isStartDateEqualEndDate, setIsStartDateEqualEndDate] = useState<boolean>(true);

  const [isSpecificWorkerRequired, setIsSpecificWorkerRequired] = useState<boolean>(false);

  const [showFindingTeachersSpinner, setShowFindingTeachersSpinner] = useState<boolean>(false);

  useEffect(() => {
    if (userContext.admin) {
      schoolApiClient.get(`/workers/recent`).then((response) => {
        setRecentWorkers(response.data);
      });
    }
  }, [userContext.admin]);

  const formSubmitHandler = async (data: NewJobInputs) => {
    setShowFindingTeachersSpinner(true);

    data.startDate.setHours(6);
    data.endDate.setHours(6);

    const requestParams = {
      ...data,
      roleType: roleType,

      schoolId: userContext.admin.school.schoolId,

      isLongTermSupply: data.isLongTermSupply || false,
      halfDay: data.halfDay === HalfDay.FULL_DAY ? null : data.halfDay,

      workerId: data.specificWorker?.workerId,
    };

    // Request params are automatically populated by ...data as this automatically pulls in any form properties from the parent, but means we need to delete specificWorker from the requestParams
    delete requestParams.specificWorker;

    try {
      const response = await schoolApiClient.post("/jobs", requestParams);
      let requestedJob = response.data as RequestedJob;

      navigate(getJobRoute(requestedJob.job.jobId), {
        state: { requestedJob },
      });
      setShowFindingTeachersSpinner(false);
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        setShowFindingTeachersSpinner(false);
        console.error("Job failed to create");

        alert(`Sorry that Job failed to create: ${error.response.data}`);
      } else {
        alert(`Sorry that Job failed to create, please try again or contact Pliny support.`);
      }
    }
  };

  const updateEndDateToBeStartDateOrLater = (event: Date | null) => {
    if (event && getValues("endDate") < event) {
      setValue("endDate", event, { shouldValidate: true });
    }
  };

  const checkIfStartDateEqualsEndDate = () => {
    if (getValues("startDate")?.getTime() === getValues("endDate")?.getTime()) {
      setIsStartDateEqualEndDate(true);
    } else {
      setIsStartDateEqualEndDate(false);
      setValue("halfDay", null);
    }
  };

  const isSpecificWorkerRequiredHandler = () => {
    if (isSpecificWorkerRequired) {
      setValue("specificWorker", null);
    }
    setIsSpecificWorkerRequired(!isSpecificWorkerRequired);
  };

  if (showFindingTeachersSpinner) {
    return <FindingWorkerSpinner />;
  }

  return (
    <SimpleCard title={"Request a Pliny"}>
      <form onSubmit={handleSubmit(formSubmitHandler)}>
        {children}
        <div>
          <Controller
            name={"startDate"}
            control={control}
            rules={{
              required: true,
              min: todaysDate as any,
            }}
            render={({ field: { onChange, value } }) => (
              <DatePicker
                label="Start Date"
                inputFormat={"dd/MM/yyyy"}
                value={value}
                minDate={todaysDate}
                onChange={(event) => {
                  onChange(event);
                  updateEndDateToBeStartDateOrLater(event);
                  checkIfStartDateEqualsEndDate();
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    id={"StartDate"}
                    error={!!errors.startDate}
                    helperText={errors.startDate ? "Please enter a valid start date that is today or later" : ""}
                  />
                )}
              />
            )}
          />
          <Controller
            name={"endDate"}
            control={control}
            rules={{
              required: true,
              min: getValues("startDate"),
            }}
            render={({ field: { onChange, value } }) => (
              <DatePicker
                label="End Date"
                inputFormat={"dd/MM/yyyy"}
                value={value}
                minDate={getValues("startDate")}
                onChange={(event) => {
                  onChange(event);
                  checkIfStartDateEqualsEndDate();
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={!!errors.endDate}
                    helperText={errors.endDate ? "Please enter a valid end date that is the same or after the start date" : ""}
                  />
                )}
              />
            )}
          />

          {isStartDateEqualEndDate && (
            <Controller
              name={"halfDay"}
              control={control}
              render={({ field: { onChange } }) => (
                <RadioGroup row aria-labelledby="half-day" style={{ display: "flex" }} defaultValue={HalfDay.FULL_DAY}>
                  <FormControlLabel
                    value={HalfDay.FULL_DAY}
                    control={<Radio />}
                    label="Full Day"
                    onChange={(event) => {
                      onChange(event);
                      setValue("halfDay", HalfDay.FULL_DAY);
                    }}
                  />
                  <FormControlLabel
                    value={HalfDay.AM}
                    control={<Radio />}
                    label="AM"
                    onChange={(event) => {
                      onChange(event);
                      setValue("halfDay", HalfDay.AM);
                    }}
                  />
                  <FormControlLabel
                    value={HalfDay.PM}
                    control={<Radio />}
                    label="PM"
                    onChange={(event) => {
                      onChange(event);
                      setValue("halfDay", HalfDay.PM);
                    }}
                  />
                </RadioGroup>
              )}
            />
          )}
        </div>

        <div>
          <Controller
            name={"isLongTermSupply"}
            control={control}
            render={({ field: { onChange, value } }) => (
              <FormControlLabel
                value={value}
                control={<Checkbox />}
                label="Does this job require planning and marking?"
                labelPlacement="end"
                onChange={onChange}
                style={{
                  paddingLeft: 9,
                }}
              />
            )}
          />
        </div>
        <div>
          <FormControlLabel
            value={isSpecificWorkerRequired}
            control={<Checkbox />}
            label="Do you want to request a specific pliny?"
            labelPlacement="end"
            onChange={isSpecificWorkerRequiredHandler}
            style={{
              paddingLeft: 9,
            }}
          />
        </div>

        {isSpecificWorkerRequired && recentWorkers && (
          <Controller
            name={"specificWorker"}
            control={control}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                id={"specificWorker"}
                options={recentWorkers}
                getOptionLabel={(option) => `${option.forename} ${option.surname}`}
                value={value || null}
                onChange={(event, item) => {
                  onChange(item);
                }}
                isOptionEqualToValue={(option, val) => option.workerId === val.workerId}
                renderInput={(params) => <TextField {...params} label="Recent Plinys" variant="outlined" />}
              />
            )}
          />
        )}

        <SubmitButton title={"Send"} endIcon={<SendIcon />} onClickHandler={handleSubmit(formSubmitHandler)} isFormValid={isValid} />
      </form>
    </SimpleCard>
  );
};

export default RequestWorkerBaseForm;
