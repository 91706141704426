import React from "react";
import { ReactJSXElement } from "@emotion/react/types/jsx-namespace";
import { Box } from "@mui/material";

export default function WorkersNotifiedListItem(props: { children: ReactJSXElement[] }) {
  return (
    <Box
      style={{
        display: "flex",
        alignItems: "center",
        flexWrap: "wrap",
        padding: 10,
      }}
    >
      {props.children}
    </Box>
  );
}
