import { addDays, isSameDay, isWeekend } from "date-fns";

export const formatDate = (date: Date) => {
  date = parseDate(date);

  const options: Intl.DateTimeFormatOptions = {
    weekday: "short",
    month: "short",
    day: "numeric",
  };

  return new Intl.DateTimeFormat("en-GB", options).format(date);
};

export const getTodaysDateAtMidnight = (): Date => {
  let todaysDate = new Date();
  todaysDate.setHours(0, 0, 0, 0);
  return todaysDate;
};

export const getTomorrowsDateAtMidnight = (): Date => {
  let tomorrowsDate = getTodaysDateAtMidnight();
  tomorrowsDate = addDays(tomorrowsDate, 1);
  return tomorrowsDate;
};

// TODO need to do this in the backend so schools don't get charged for not worked days
const isBankHoliday = (currentDate: Date): boolean => {
  const bankHolidays = [new Date("2024-05-06")];

  return bankHolidays.some((date) => isSameDay(date, currentDate));
};

const parseDate = (date: any): Date => {
  if (!date) {
    return null;
  }
  if (!(date instanceof Date) || isNaN(date.getTime())) {
    return new Date(date);
  }
  return date;
};

export const calculateBusinessDays = (startDate: Date, endDate: Date): number => {
  startDate = parseDate(startDate);
  endDate = parseDate(endDate);

  let businessDays = 0;
  let currentDate = startDate;

  while (currentDate <= endDate) {
    if (!isWeekend(currentDate) && !isBankHoliday(currentDate)) {
      businessDays++;
    }
    currentDate = addDays(currentDate, 1);
  }

  return businessDays;
};
