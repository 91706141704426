import { ReactJSXElement } from "@emotion/react/types/jsx-namespace";
import { Typography } from "@mui/material";
import React from "react";
import WorkersNotifiedListItem from "./WorkersNotifiedListItem";

export default function WorkerNotifiedTextIcon(props: { text: string; children: ReactJSXElement }) {
  return (
    <WorkersNotifiedListItem>
      {props.children}
      <Typography display="inline" sx={{ paddingLeft: 3 }}>
        {props.text}
      </Typography>
    </WorkersNotifiedListItem>
  );
}
