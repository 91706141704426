import { Job } from "../../model/ModelTypes";
import { disabledBackgroundColor, errorBackgroundColor, infoBackgroundColor, successBackgroundColor, warningBackgroundColor } from "../../constants/Colors";

export type WorkerSelectorStatus = "CANCELLED" | "WORKER" | "REQUESTED" | "NOTIFIED" | "NO_PLINYS";

export enum WorkerSelectorStatusEnum {
  CANCELLED = "CANCELLED",
  WORKER = "WORKER",
  REQUESTED = "REQUESTED",
  NOTIFIED = "NOTIFIED",
  NO_PLINYS = "NO_PLINYS",
}

export const getWorkerSelectorStatus = (job: Job): WorkerSelectorStatusEnum => {
  const worker = job.worker;

  if (job.isCancelled) {
    return WorkerSelectorStatusEnum.CANCELLED;
  }
  if (worker) {
    return WorkerSelectorStatusEnum.WORKER;
  }
  if (job.rankedWorkersRequested) {
    return WorkerSelectorStatusEnum.REQUESTED;
  }
  if ((job.notifiedWorkers && job.notifiedWorkers.length > 0) || job.workerSelectorRunning) {
    return WorkerSelectorStatusEnum.NOTIFIED;
  }
  return WorkerSelectorStatusEnum.NO_PLINYS;
};

export const getWorkerSelectorStatusColor = (status: WorkerSelectorStatusEnum): string => {
  switch (status) {
    case WorkerSelectorStatusEnum.CANCELLED:
      return disabledBackgroundColor;
    case WorkerSelectorStatusEnum.WORKER:
      return successBackgroundColor;
    case WorkerSelectorStatusEnum.REQUESTED:
      return infoBackgroundColor;
    case WorkerSelectorStatusEnum.NOTIFIED:
      return warningBackgroundColor;
    case WorkerSelectorStatusEnum.NO_PLINYS:
      return errorBackgroundColor;
  }
};
