import { styled } from "@mui/material/styles";
import { darkPrimaryColor } from "../constants/Colors";

const DrawerHeaderSpacing = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
  backgroundColor: darkPrimaryColor,
}));

export default DrawerHeaderSpacing;
