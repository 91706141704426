import * as React from "react";
import { useContext, useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import NavBars from "../components/NavBars";
import { BrowserRouter, Outlet, Route, Routes } from "react-router-dom";
import Main from "../components/Main";
import { JobsNavigation, NavbarItem, ReviewsNavigation, SettingsNavigation } from "../components/nav/NavigationRoutes";
import { UserContext } from "../context/UserContext";
import { AppContext } from "../context/AppContext";
import WorkerPage from "./plinys/PlinyDetailPage";
import JobDetailsPage from "./jobs/JobDetailsPage";
import { JobContextProvider } from "../context/JobContext";

function AppRoutes(props: any) {
  const userContext = useContext(UserContext);
  const appContext = useContext(AppContext);

  useEffect(() => {
    userContext.getAdminDetails();
    appContext.populateDefaultSubjects();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const theme = useTheme();

  const [open, setOpen] = React.useState(true);

  return (
    <div className="App" style={{ height: "100%" }}>
      <BrowserRouter>
        <Box sx={{ display: "flex" }}>
          <NavBars theme={theme} open={open} setOpen={setOpen} signOut={props.signOut} />
          <Outlet />
        </Box>
        <Main open={open}>
          <Routes>
            {mapRoutes(JobsNavigation)}
            {mapRoutes(ReviewsNavigation)}
            {mapRoutes(SettingsNavigation)}
            <Route
              path={"/jobs"}
              element={
                <JobContextProvider>
                  <JobDetailsPage />
                </JobContextProvider>
              }
            />
            <Route path={"/pliny"} element={<WorkerPage />} />
          </Routes>
        </Main>
      </BrowserRouter>
    </div>
  );
}

const mapRoutes = (routeNavigation: NavbarItem[]) => {
  return (
    <>
      {routeNavigation.map(({ path, Component }) => (
        <Route key={path} path={path} element={Component} />
      ))}
    </>
  );
};

export default AppRoutes;
