import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import * as React from "react";
import { Theme } from "@mui/material/styles";
import DrawerHeaderSpacing from "./DrawerHeaderSpacing";
import { drawerWidth } from "../App";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Link, useLocation } from "react-router-dom";
import { JobsNavigation, NavbarItem, ReviewsNavigation, SettingsNavigation } from "./nav/NavigationRoutes";
import { Image } from "@aws-amplify/ui-react";
import { disabledColor, primaryColor, whiteColor } from "../constants/Colors";
import { Box, ListItemButton, ListSubheader } from "@mui/material";
import { useIsSmallScreen } from "../hooks/IsSmallScreen";

function SideNavBar(
  props: {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setTransitionExited: React.Dispatch<React.SetStateAction<boolean>>;
    theme: Theme;
  },
  paperProps: { sx: { backgroundColor: string } } = {
    sx: {
      backgroundColor: whiteColor,
    },
  }
) {
  const location = useLocation();

  const handleDrawerToggle = () => {
    props.setOpen(!props.open);
  };

  const isSmallScreen = useIsSmallScreen();

  const drawerListItems = (item: NavbarItem) => {
    return (
      <Box key={item.title + "-box"} paddingX={"20px"}>
        <Link key={item.title + "-link"} to={item.path} style={{ textDecoration: "none" }} onClick={isSmallScreen ? handleDrawerToggle : undefined}>
          <ListItemButton key={item.title} selected={item.path === location.pathname}>
            <ListItemIcon key={item.title + "-icon"} color={disabledColor}>
              {item.icon}
            </ListItemIcon>
            <ListItemText key={item.title + "-text"} primary={item.title} primaryTypographyProps={{ fontWeight: 500, color: disabledColor }} />
          </ListItemButton>
        </Link>
      </Box>
    );
  };

  return (
    <Drawer
      PaperProps={paperProps}
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: drawerWidth,
          boxSizing: "border-box",
          borderWidth: 0,
        },
      }}
      variant={isSmallScreen ? "temporary" : "persistent"}
      anchor="left"
      open={props.open}
      onClose={handleDrawerToggle}
      SlideProps={{
        onExited: () => {
          props.setTransitionExited(true);
        },
      }}
    >
      <DrawerHeaderSpacing sx={{ backgroundColor: primaryColor }}>
        <Image alt="Pliny logo" src={`pliny-white-title-logo.png`} height={50} />
      </DrawerHeaderSpacing>
      <List
        sx={{
          // selected and (selected + hover) states
          "&& .Mui-selected, && .Mui-selected:hover": {
            color: whiteColor,
            "&, & .MuiListItemIcon-root, & .MuiTypography-root": {
              color: primaryColor,
            },
          },
          // hover states
          "& .MuiListItemButton-root:hover": {
            // bgcolor: 'orange',
            "&": {
              color: primaryColor,
            },
          },
        }}
      >
        <ListSubheader component="div">Jobs</ListSubheader>
        {JobsNavigation.map(drawerListItems)}
        <ListSubheader component="div">Reviews</ListSubheader>
        {ReviewsNavigation.map(drawerListItems)}
        <ListSubheader component="div">Settings</ListSubheader>
        {SettingsNavigation.map(drawerListItems)}
      </List>
    </Drawer>
  );
}

export default SideNavBar;
