import * as React from "react";
import { useContext } from "react";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import AppBar from "./AppBar";
import { whiteColor } from "../constants/Colors";
import Typography from "@mui/material/Typography";
import { Box, Grid, Menu, MenuItem } from "@mui/material";
import { UserContext } from "../context/UserContext";
import NoStyledButton from "./buttons/NoStyledButton";
import { useSignOut } from "../hooks/SignOut";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { useIsSmallScreen } from "../hooks/IsSmallScreen";
import { SchoolProfilePicture } from "./images/SchoolProfilePicture";

function TopNavBar(props: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  transitionExited: boolean;
  setTransitionExited: React.Dispatch<React.SetStateAction<boolean>>;
  signOut: ReturnType<typeof useAuthenticator>["signOut"];
}) {
  const userContext = useContext(UserContext);

  const handleSignOut = useSignOut(props.signOut);

  const isSmallScreen = useIsSmallScreen();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const avatarMenuOpen = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDrawerOpen = (open: boolean, setOpen: React.Dispatch<React.SetStateAction<boolean>>) => {
    setOpen(!open);
    props.setTransitionExited(false);
  };

  const hideAvatar = isSmallScreen && (props.open || !props.transitionExited);

  return (
    <AppBar position="fixed" open={props.open} elevation={0}>
      <Toolbar>
        <Grid item lg={6} md={6} sm={12} xs={12} alignItems={"start"}>
          <IconButton color="inherit" aria-label="open drawer" onClick={() => handleDrawerOpen(props.open, props.setOpen)} edge="start" sx={{ mr: 2 }}>
            <MenuIcon sx={{ color: whiteColor }} />
          </IconButton>
        </Grid>

        <Grid container justifyContent="right" sx={hideAvatar ? { display: { xs: "none", sm: "inline" } } : {}}>
          <Typography color={whiteColor} align={"right"} justifyContent={"center"} marginTop={"14px"}>
            Hi
            <Box component="span" fontWeight={500} justifyContent={"center"}>
              {" "}
              {userContext?.admin?.forename}
            </Box>
          </Typography>

          <NoStyledButton onClick={handleClick}>
            <SchoolProfilePicture />
          </NoStyledButton>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={avatarMenuOpen}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem onClick={handleSignOut}>Logout</MenuItem>
          </Menu>
        </Grid>
      </Toolbar>
    </AppBar>
  );
}

export default TopNavBar;
