import * as React from "react";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../context/UserContext";
import useInput from "../../hooks/Input";
import SimpleCard from "../../components/SimpleCard";
import { InputAdornment, TextField, Typography } from "@mui/material";
import SubmitButton from "../../components/buttons/SubmitButton";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { formatDateTimeLocal, parseTime } from "../../helpers/TimeHelper";
import theme from "../../constants/Theme";
import schoolApiClient from "../../services/SchoolApiClient";

export default function SchoolSettings() {
  const [showSuccessMessage, setShowSuccessMessage] = useState<boolean>(false);

  const { admin, setAdmin } = useContext(UserContext);

  useEffect(() => {
    if (admin) {
      maxShortTermDailyRate_changeValueIfUntouched(admin.school.schoolSettings.maxShortTermDailyRate);
      startTime_changeHandler(parseTime(admin.school.schoolSettings.startTime));
      endTime_changeHandler(parseTime(admin.school.schoolSettings.endTime));
    }
  }, [admin]); // eslint-disable-line react-hooks/exhaustive-deps

  const minimumMaxShortTermDailyRate = parseInt(process.env.REACT_APP_MIN_MAX_SHORT_TERM_DAILY_RATE ? process.env.REACT_APP_MIN_MAX_SHORT_TERM_DAILY_RATE : "");

  const {
    value: maxShortTermDailyRate,
    isValid: maxShortTermDailyRate_isValid,
    displayError: maxShortTermDailyRate_displayError,
    valueChangeHandler: maxShortTermDailyRate_changeHandler,
    inputBlurHandler: maxShortTermDailyRate_blurHandler,
    changeValueIfUntouched: maxShortTermDailyRate_changeValueIfUntouched,
  } = useInput((value) => typeof value === "number" && value >= minimumMaxShortTermDailyRate);

  const { value: startTime, isValid: startTime_isValid, valueChangeHandler: startTime_changeHandler } = useInput((value) => value instanceof Date);

  const { value: endTime, isValid: endTime_isValid, valueChangeHandler: endTime_changeHandler } = useInput((value) => value instanceof Date);

  const formIsValid = maxShortTermDailyRate_isValid && startTime_isValid && endTime_isValid;

  const formSubmitHandler = (event: any) => {
    event.preventDefault();

    if (formIsValid) {
      const requestParams = {
        maxShortTermDailyRate: maxShortTermDailyRate,
        startTime: formatDateTimeLocal(startTime),
        endTime: formatDateTimeLocal(endTime),
      };

      schoolApiClient.patch("/settings", requestParams).then((response) => {
        admin.school.schoolSettings = response.data;
        setAdmin(admin);
        setShowSuccessMessage(true);
      });
    }
  };

  return (
    <SimpleCard title={"School Settings"}>
      <TextField
        type={"number"}
        id={"MaxShortTermSupplyRate"}
        label={"Max Short Term Supply Rate"}
        value={maxShortTermDailyRate}
        onChange={(event) => {
          maxShortTermDailyRate_changeHandler(event);
          setShowSuccessMessage(false);
        }}
        onBlur={maxShortTermDailyRate_blurHandler}
        error={maxShortTermDailyRate_displayError}
        helperText={maxShortTermDailyRate_displayError ? "Your max short term supply rate must be at least £" + minimumMaxShortTermDailyRate : ""}
        InputProps={{
          startAdornment: <InputAdornment position="start">£</InputAdornment>,
        }}
      />
      <div>
        <TimePicker
          label="School Start Time"
          value={startTime}
          onChange={(event) => {
            startTime_changeHandler(event);
            setShowSuccessMessage(false);
          }}
          renderInput={(params) => <TextField {...params} id={"SchoolStartTime"} />}
          minTime={new Date(0, 0, 0, 6)}
          maxTime={new Date(0, 0, 0, 12)}
        />
      </div>
      <div>
        <TimePicker
          label="School End Time"
          value={endTime}
          onChange={(event) => {
            endTime_changeHandler(event);
            setShowSuccessMessage(false);
          }}
          renderInput={(params) => <TextField {...params} id={"SchoolEndTime"} />}
          minTime={new Date(0, 0, 0, 12)}
          maxTime={new Date(0, 0, 0, 18)}
        />
      </div>

      {showSuccessMessage && <Typography color={theme.palette.success.main}>Settings successfully saved!</Typography>}

      <SubmitButton id={"SaveSchoolSettings"} onClickHandler={formSubmitHandler} isFormValid={formIsValid && !showSuccessMessage} />
    </SimpleCard>
  );
}
