import TopNavBar from "./TopNavBar";
import SideNavBar from "./SideNavBar";
import * as React from "react";
import { useState } from "react";
import { Theme } from "@mui/material/styles";
import { useAuthenticator } from "@aws-amplify/ui-react";

function NavBars(props: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  theme: Theme;
  signOut: ReturnType<typeof useAuthenticator>["signOut"];
}) {
  const [transitionExited, setTransitionExited] = useState<boolean>(false);

  return (
    <>
      <TopNavBar
        open={props.open}
        setOpen={props.setOpen}
        transitionExited={transitionExited}
        setTransitionExited={setTransitionExited}
        signOut={props.signOut}
      />
      <SideNavBar open={props.open} setOpen={props.setOpen} setTransitionExited={setTransitionExited} theme={props.theme} />
    </>
  );
}

export default NavBars;
