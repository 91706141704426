import { Table, TableBody, TableContainer, TableHead, TableRow } from "@mui/material";
import * as React from "react";
import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { UserContext } from "../../context/UserContext";
import { calculateBusinessDays, formatDate } from "../../helpers/DateFormatter";
import { Job, PrimaryTeacherJob, roleTypeToCapitalisedString, SchoolType, SecondaryTeacherJob } from "../../model/ModelTypes";
import { getJobRoute } from "../../routes/RouteUrls";
import schoolApiClient from "../../services/SchoolApiClient";
import { PageLoadError } from "../nav/PageLoadError";
import { PageLoadingSpinner } from "../nav/PageLoadingSpinner";
import PlinysTableCell from "./upcoming-plinys/UpcomingPlinysCell";
import { WorkerSelectorStatus } from "./WorkerSelectorStatus";
import { getWorkerSelectorStatus, getWorkerSelectorStatusColor } from "./WorkerSelectorStatusHelper";

const UpcomingPlinysTable = () => {
  const [jobs, setJobs] = useState<Job[]>();
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [error, setError] = useState<string>("");

  const { admin } = useContext(UserContext);

  useEffect(() => {
    const getFutureJobs = () => {
      const schoolId = admin?.school?.schoolId;

      if (schoolId) {
        schoolApiClient
          .get("/jobs/future")
          .then((response) => {
            let jobList = response.data;
            if (jobList === undefined || jobList.length > 0) {
              setJobs(jobList);
              setIsLoaded(true);
            } else {
              setError("No upcoming supply requests.");
              setIsLoaded(true);
            }
          })
          .catch(() => {
            setError("Error getting supply requests list");
            setIsLoaded(true);
          });
      }
    };

    getFutureJobs();
  }, [admin?.school?.schoolId]);

  if (isLoaded) {
    if (error === "" && jobs) {
      return (
        <TableContainer>
          <Table stickyHeader className="whitespace-pre">
            <TableHead>
              <TableRow>
                <PlinysTableCell id={"startDate"}>Start Date</PlinysTableCell>
                <PlinysTableCell id={"endDate"}>End Date</PlinysTableCell>
                <PlinysTableCell id={"joblength"}>Number of days</PlinysTableCell>
                <PlinysTableCell id={"jobType"}>Role</PlinysTableCell>
                <PlinysTableCell id={"jobDetail"}>{admin.school.schoolType === SchoolType.PRIMARY ? "Class" : "Subject"}</PlinysTableCell>
                <PlinysTableCell id={"schoolDailyRate"}>Daily Rate</PlinysTableCell>
                <PlinysTableCell id={"status"}>Status</PlinysTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {jobs.map((job) => {
                const rowBackgroundColor = getWorkerSelectorStatusColor(getWorkerSelectorStatus(job));

                return (
                  <TableRow key={job.jobId} component={Link} to={getJobRoute(job.jobId)} sx={{ textDecoration: "none", backgroundColor: rowBackgroundColor }}>
                    <PlinysTableCell id={`${job.jobId}-startDate`}>{formatDate(job.startDate)}</PlinysTableCell>
                    <PlinysTableCell id={`${job.jobId}-endDate`}>{formatDate(job.endDate)}</PlinysTableCell>
                    <PlinysTableCell id={`${job.jobId}-joblength`}>{calculateBusinessDays(job.startDate, job.endDate)}</PlinysTableCell>
                    <PlinysTableCell id={`${job.jobId}-jobType`}>{roleTypeToCapitalisedString(job.roleType)}</PlinysTableCell>
                    <PlinysTableCell id={`${job.jobId}-jobDetail`}>
                      {admin.school.schoolType === SchoolType.PRIMARY ? (job as PrimaryTeacherJob).primaryYear : (job as SecondaryTeacherJob).subject.name}
                    </PlinysTableCell>
                    <PlinysTableCell id={`${job.jobId}-schoolDailyRate`}>{job.schoolDailyRate && `£${job.schoolDailyRate}`}</PlinysTableCell>
                    <PlinysTableCell id={`${job.jobId}-status`}>
                      <WorkerSelectorStatus job={job} />
                    </PlinysTableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      );
    } else {
      return <PageLoadError error={error} />;
    }
  } else {
    return <PageLoadingSpinner />;
  }
};

export default UpcomingPlinysTable;
