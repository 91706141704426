import { useState } from "react";

const useInput = (validateValue: (enteredValue: any) => {}, defaultValue: string = "") => {
  const [enteredValue, setEnteredValue] = useState<any>(defaultValue);
  const [isTouched, setIsTouched] = useState<boolean>(defaultValue !== "");

  const valueChangeHandler = (event: any, value?: any) => {
    if (value) {
      setEnteredValue(value);
    } else if (event instanceof Date) {
      setEnteredValue(event);
    } else if (event) {
      if (event.target.type === "checkbox") {
        setEnteredValue(event.target.checked);
      } else if (event.target.type === "number") {
        setEnteredValue(event.target.valueAsNumber);
      } else {
        setEnteredValue(event.target.value);
      }
    }

    setIsTouched(true);
  };

  const inputBlurHandler = () => {
    setIsTouched(true);
  };

  const changeValueIfUntouched = (value: any = "") => {
    if (!isTouched) {
      setEnteredValue(value);
      setIsTouched(true);
    }
  };

  const isValid = validateValue(enteredValue) && isTouched;

  const displayError = !isValid && isTouched;

  return {
    value: enteredValue,
    isValid,
    displayError,
    valueChangeHandler,
    inputBlurHandler,
    changeValueIfUntouched,
  };
};

export default useInput;
