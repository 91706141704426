import Rating from "@mui/material/Rating";
import styled from "@emotion/styled";
import { darkPrimaryColor, primaryColor } from "../../constants/Colors";

const StyledRating = styled(Rating)({
  "& .MuiRating-iconFilled": {
    color: darkPrimaryColor,
  },
  "& .MuiRating-iconHover": {
    color: primaryColor,
  },
});

export default StyledRating;
