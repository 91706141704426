import { Job, RoleType, Worker } from "../../../model/ModelTypes";
import { getField, getRole } from "../../plinys/roles/RoleHelper";
import { listSpecialistSubjects } from "../../../helpers/SubjectLister";

export const getRoleSpecificDetails = (worker: Worker, job: Job) => {
  let roleSpecificDetails = null;
  let role = getRole(worker, job.roleType);
  if (job.roleType === RoleType.SECONDARY_TEACHER) {
    roleSpecificDetails = listSpecialistSubjects(getField(role, "specialistSubjects"));
  } else if (job.roleType === RoleType.PRIMARY_TEACHER) {
    roleSpecificDetails = getField(role, "primaryExperienceYears");
  }
  return roleSpecificDetails;
};
