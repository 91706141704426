import React from "react";
import RequestAPlinyPage from "../../routes/jobs/RequestAPlinyPage";
import UpcomingPlinysPage from "../../routes/jobs/UpcomingPlinysPage";
import ReviewPlinysPage from "../../routes/reviews/ReviewPlinysPage";
import SettingsPage from "../../routes/settings/SettingsPage";
import PeopleIcon from "@mui/icons-material/People";
import AddIcon from "@mui/icons-material/Add";
import RateReviewIcon from "@mui/icons-material/RateReview";
import BusinessIcon from "@mui/icons-material/Business";
import LogoutIcon from "@mui/icons-material/Logout";

export interface NavbarItem {
  title: string;
  path: string;
  icon: JSX.Element;
  cName: string;
  Component: JSX.Element;
}

export const JobsNavigation: NavbarItem[] = [
  {
    title: "Upcoming Plinys",
    path: "/",
    icon: <PeopleIcon key={"peopleIcon"} />,
    cName: "nav-text",
    Component: <UpcomingPlinysPage />,
  },
  {
    title: "Request Pliny",
    path: "/request-pliny",
    icon: <AddIcon key={"addIcon"} />,
    cName: "nav-text",
    Component: <RequestAPlinyPage />,
  },
];

export const ReviewsNavigation: NavbarItem[] = [
  {
    title: "Review Plinys",
    path: "/review-plinys",
    icon: <RateReviewIcon key={"rateReviewIcon"} />,
    cName: "nav-text",
    Component: <ReviewPlinysPage />,
  },
];

export const SettingsNavigation: NavbarItem[] = [
  {
    title: "Settings",
    path: "/settings",
    icon: <BusinessIcon key={"businessIcon"} />,
    cName: "nav-text",
    Component: <SettingsPage key={"settingsPage"} />,
  },
];

export let SignOutNavigation: NavbarItem = {
  title: "Sign Out",
  path: "/",
  icon: <LogoutIcon key={"logoutIcon"} />,
  cName: "nav-text",
  Component: <SettingsPage />,
};
