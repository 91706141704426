import { Theme } from "@aws-amplify/ui-react";
import { primaryColor, secondaryColor } from "./Colors";

export const amplifyTheme: Theme = {
  name: "amplify-theme",
  tokens: {
    colors: {
      font: {
        primary: { value: primaryColor },
        secondary: { value: secondaryColor },
      },
      teal: {
        100: { value: primaryColor },
        90: { value: primaryColor },
        80: { value: primaryColor },
        60: { value: primaryColor },
        40: { value: primaryColor },
        20: { value: primaryColor },
        10: { value: primaryColor },
      },
    },
  },
};
