import { CircularProgress, Grid } from "@mui/material";
import * as React from "react";

type PageLoadingSpinnerProps = {
  size?: number;
};

export const PageLoadingSpinner = ({ size }: PageLoadingSpinnerProps) => (
  <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center" style={{ minHeight: "30vh" }}>
    <CircularProgress size={size} />
  </Grid>
);
