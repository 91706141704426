import axiosClient from "axios";
import { getBaseUrl, setupHeaders } from "./AwsClientHelper";

export const awsPublicClient = axiosClient.create({
  baseURL: getBaseUrl(),
});

awsPublicClient.interceptors.request.use(
  async (config) => {
    try {
      config = await setupHeaders(config);
    } catch (err) {
      console.log(err);
    }
    return config;
  },

  (error) => {
    return Promise.reject(error);
  }
);
