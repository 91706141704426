import React from "react";
import { Button } from "@mui/material";

const NoStyledButton = (props: { children: JSX.Element; onClick: any }) => {
  return (
    <Button
      sx={{
        outline: "none",
        background: "transparent",
        border: "1px solid transparent",
        marginTop: 0,
      }}
      onClick={props.onClick}
    >
      {props.children}
    </Button>
  );
};

export default NoStyledButton;
