import { Job, PrimaryTeacherJob, RoleType, SecondaryTeacherJob } from "../../../model/ModelTypes";
import PrimaryTeacherJobDetails from "./PrimaryTeacherJobDetails";
import SecondaryTeacherJobDetails from "./SecondaryTeacherJobDetails";

export type JobRoleSpecificDetailsProps = {
  job: Job;
};

const JobRoleSpecificDetails = ({ job }: JobRoleSpecificDetailsProps) => {
  switch (job.roleType) {
    case RoleType.PRIMARY_TEACHER:
      return <PrimaryTeacherJobDetails job={job as PrimaryTeacherJob} />;
    case RoleType.SECONDARY_TEACHER:
      return <SecondaryTeacherJobDetails job={job as SecondaryTeacherJob} />;
    default:
      return null;
  }
};

export default JobRoleSpecificDetails;
