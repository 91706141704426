import { Button, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import DownloadButton from "../../components/buttons/DownloadButton";
import { PlinyProfilePicture } from "../../components/images/PlinyProfilePicture";
import { PageLoadError } from "../../components/nav/PageLoadError";
import { PageLoadingSpinner } from "../../components/nav/PageLoadingSpinner";
import RoleCard from "../../components/plinys/roles/RoleCard";
import StyledRating from "../../components/reviews/Rating.styled";
import SimpleCard from "../../components/SimpleCard";
import { Worker, WorkerProfileForSchools, WorkerReviews } from "../../model/ModelTypes";
import { getWorkerCvKey } from "../../services/AwsStorageService";
import schoolApiClient from "../../services/SchoolApiClient";

const PlinyDetailPage = () => {
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [error, setError] = useState<string>("");

  const [averageRating, setAverageRating] = useState<number>();
  const [reviews, setReviews] = useState<Array<WorkerReviews>>();
  const [worker, setWorker] = useState<Worker>();

  const [searchParams] = useSearchParams();
  const workerId = searchParams.get("workerId");

  const navigate = useNavigate();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    schoolApiClient
      .get(`/workers/${workerId}`)
      .then((response) => {
        const workerProfile: WorkerProfileForSchools = response.data;
        const workerReviews = workerProfile.reviews;

        setReviews(workerReviews);
        setAverageRating(workerProfile.averageRating);
        setWorker(workerProfile.worker);

        setIsLoaded(true);
      })
      .catch((error) => {
        setError("Error getting pliny profile");
        setIsLoaded(true);
        console.log(error);
      });
  }, [workerId]);

  const handleGoBack = () => {
    navigate(-1);
  };

  if (isLoaded && worker) {
    if (error === "") {
      // TODO-CI rewrite to use PlinyDetailsTableRow
      return (
        <Grid container spacing={3}>
          <Grid item lg={8} md={8} sm={12} xs={12}>
            <SimpleCard title={"Personal Details"}>
              <Table className="whitespace-pre">
                <TableBody>
                  <TableRow key="Name">
                    <TableCell className="px-0 capitalize" align="left" id={"name"}>
                      Name
                    </TableCell>
                    <TableCell className="px-0 capitalize" align="left" id={"nameResult"}>
                      {worker.forename + " " + worker.surname}
                    </TableCell>
                  </TableRow>
                  <TableRow key="Email">
                    <TableCell className="px-0 capitalize" align="left" id={"email"}>
                      Email
                    </TableCell>
                    <TableCell className="px-0" align="left" id={"emailResult"}>
                      {worker.email}
                    </TableCell>
                  </TableRow>
                  <TableRow key="Phone">
                    <TableCell className="px-0" align="left" id={"phoneNumber"}>
                      Phone
                    </TableCell>
                    <TableCell className="px-0" align="left" id={"phoneNumberResult"}>
                      {worker.phoneNumber}
                    </TableCell>
                  </TableRow>
                  <TableRow key="DBS">
                    <TableCell className="px-0 capitalize" align="left" id={"subject"}>
                      DBS Number
                    </TableCell>
                    <TableCell className="px-0 capitalize" align="left" id={"subjectResult"}>
                      {worker.dbsNumber}
                    </TableCell>
                  </TableRow>
                  <TableRow key="Reference">
                    <TableCell className="px-0 capitalize" align="left" id={"subject"}>
                      Worker Reference Number
                    </TableCell>
                    <TableCell className="px-0 capitalize" align="left" id={"subjectResult"}>
                      {worker.workerReferenceNumber}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </SimpleCard>

            {worker.roles?.map((role) => (
              <RoleCard key={role.roleType} role={role} />
            ))}

            {!!averageRating && !!reviews && <WorkerReviewsCard averageRating={averageRating} workerReviews={reviews} />}
          </Grid>

          <Grid item lg={4} md={4} sm={12} xs={12}>
            <SimpleCard title={"Headshot"}>
              <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center">
                <Grid item xs={3}>
                  <PlinyProfilePicture worker={worker} />
                </Grid>
              </Grid>
            </SimpleCard>

            <SimpleCard title={"Documents"}>
              <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center">
                <Grid item xs={3}>
                  <DownloadButton objectKey={getWorkerCvKey(worker)}>Download Teacher CV</DownloadButton>
                </Grid>
              </Grid>
            </SimpleCard>

            <SimpleCard title={"Return to Pliny List"}>
              <Button id={"back-button"} variant="contained" color="secondary" onClick={handleGoBack}>
                Back
              </Button>
            </SimpleCard>
          </Grid>
        </Grid>
      );
    } else {
      return <PageLoadError error={error} />;
    }
  } else {
    return <PageLoadingSpinner />;
  }
};

const WorkerReviewsCard = (props: { averageRating: number; workerReviews: Array<WorkerReviews> }) => {
  const numberOfWorkerReviewsBeforeDisplaying: number = !!process.env.REACT_APP_NUMBER_OF_WORKER_REVIEWS_BEFORE_DISPLAYING
    ? parseInt(process.env.REACT_APP_NUMBER_OF_WORKER_REVIEWS_BEFORE_DISPLAYING)
    : 1;

  const averageRating = props.averageRating;
  const reviews = props.workerReviews;

  if (reviews?.length >= numberOfWorkerReviewsBeforeDisplaying) {
    return (
      <SimpleCard
        title={"Reviews"}
        titleDecoration={!!averageRating && <StyledRating name={"pliny-rating"} size="large" value={averageRating * 20} readOnly />}
      >
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell id={"reviewDate-header"}>Date</TableCell>
                <TableCell id={"schoolName-header"}>School</TableCell>
                <TableCell id={"subjectName-header"}>Subject Taught</TableCell>
                <TableCell id={"review-header"}>Review</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {reviews?.map((workerReview) => (
                <TableRow key={`workerReview-${workerReview.reviewDate}`}>
                  <TableCell id={workerReview.reviewDate}>{new Date(workerReview.reviewDate).toLocaleDateString()}</TableCell>
                  <TableCell id={workerReview.schoolName}>{workerReview.schoolName}</TableCell>
                  <TableCell id={workerReview.subjectName}>{workerReview.subjectName}</TableCell>
                  <TableCell id={workerReview.review}>{workerReview.review}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </SimpleCard>
    );
  } else {
    return (
      <SimpleCard title={"Reviews"}>
        <Typography>This worker is new! We don't have enough reviews to display yet.</Typography>
      </SimpleCard>
    );
  }
};

export default PlinyDetailPage;
