import { useContext } from "react";
import { UserContext } from "../context/UserContext";
import { useAuthenticator } from "@aws-amplify/ui-react";

export const useSignOut = (signOut: ReturnType<typeof useAuthenticator>["signOut"]) => {
  const userContext = useContext(UserContext);

  const handleSignOut = () => {
    signOut();
    userContext.setAdmin(null);
  };

  return handleSignOut;
};
