import { createTheme } from "@mui/material/styles";
import { primaryColor, secondaryColor, whiteColor } from "./Colors";

const theme = createTheme({
  palette: {
    primary: {
      main: primaryColor,
      contrastText: whiteColor,
    },
    secondary: {
      main: secondaryColor,
    },
    error: {
      main: "#aa0000",
    },
    success: {
      main: "#008800",
    },
    warning: {
      main: "#ffE000",
    },
  },

  typography: {
    fontFamily: ["Montserrat"].join(","),
  },

  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          marginTop: "20px",
        },
      },
    },

    MuiTextField: {
      styleOverrides: {
        root: {
          marginBottom: "10px",
          marginTop: "10px",
          marginLeft: "10px",
          marginRight: "10px",
        },
      },
    },
  },
});

export default theme;
