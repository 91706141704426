import React, { ReactNode, useMemo, useState } from "react";
import { RequestedJob } from "../model/ModelTypes";

export const JobContext = React.createContext({
  requestedJob: null as RequestedJob,
  setRequestedJob: (requestedJob: RequestedJob) => {
    // Empty as function gets populated in provider
  },
});

type JobContextProviderProps = Readonly<{
  children: ReactNode;
}>;

export function JobContextProvider({ children }: JobContextProviderProps) {
  const [requestedJob, setRequestedJob] = useState<RequestedJob>();

  const context = useMemo(
    () => ({
      requestedJob: requestedJob,
      setRequestedJob: setRequestedJob,
    }),
    [requestedJob]
  );

  return <JobContext.Provider value={context}>{children}</JobContext.Provider>;
}
