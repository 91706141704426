import { Subject } from "../model/ModelTypes";

export const listSpecialistSubjects = (subjects: undefined | Subject[]) => {
  if (!subjects) return "";
  return subjects
    .map((subject) => {
      return subject.name;
    })
    .join(", ");
};
