import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import * as React from "react";

type ThemedDialogProps = Readonly<{
  title: string;
  content: string;
  handleSubmit: () => void;
  open: boolean;
  setOpen: (open: boolean) => void;
}>;

function ThemedDialog({ title, content, handleSubmit, open, setOpen }: ThemedDialogProps) {
  const handleCancel = () => {
    setOpen(false);
  };

  const handleAccept = () => {
    setOpen(false);

    handleSubmit();
  };

  return (
    <Dialog open={open} onClose={handleCancel} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{content}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant={"outlined"} color={"secondary"} onClick={handleCancel}>
          Back
        </Button>
        {handleSubmit && (
          <Button variant={"contained"} color={"error"} onClick={handleAccept}>
            Confirm
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

export default ThemedDialog;
