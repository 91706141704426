import * as React from "react";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../context/UserContext";
import { getSchoolProfilePictureKey, getSignedS3ObjectUrl } from "../../services/AwsStorageService";
import { Avatar } from "@mui/material";

export const SchoolProfilePicture = () => {
  const [image, setImage] = useState<string>();

  const userContext = useContext(UserContext);
  const school = userContext?.admin?.school;
  const schoolId = userContext?.admin?.school?.schoolId;

  useEffect(() => {
    const getS3Image = async () => {
      const result = await getSignedS3ObjectUrl("images", getSchoolProfilePictureKey(school));
      setImage(result);
    };

    if (schoolId) {
      getS3Image();
    }
  }, [schoolId, school?.schoolAppInfo?.profilePictureFileName]);

  return <Avatar id={"ProfilePicture"} alt={`${userContext?.admin?.school?.name} logo`} src={image} />;
};
