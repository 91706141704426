import { Job } from "../../model/ModelTypes";
import { Link } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import Typography from "@mui/material/Typography";
import * as React from "react";
import PlinyDetailsLinkButton from "../plinys/PlinyDetailsLinkButton";
import { getJobRoute } from "../../routes/RouteUrls";
import { getWorkerSelectorStatus, WorkerSelectorStatusEnum } from "./WorkerSelectorStatusHelper";
import ThemedButton from "../buttons/ThemedButton";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import { getShortWorkerName } from "../../helpers/TextFormatter";

type WorkerSelectorStatusProps = Readonly<{
  job: Job;
}>;

export function WorkerSelectorStatus({ job }: WorkerSelectorStatusProps) {
  const worker = job.worker;

  const workerSelectorStatus = getWorkerSelectorStatus(job);

  switch (workerSelectorStatus) {
    case WorkerSelectorStatusEnum.CANCELLED:
      return <Typography>Job Cancelled</Typography>;
    case WorkerSelectorStatusEnum.WORKER:
      return <PlinyDetailsLinkButton worker={worker} title={getShortWorkerName(worker)} jobId={job.jobId} />;
    case WorkerSelectorStatusEnum.REQUESTED:
      return (
        <Link id={"job-" + job.jobId + "-findingPliny-link"} to={getJobRoute(job.jobId)}>
          <ThemedButton title={`Request Pliny`} style={{ marginTop: 0 }} endIcon={<QuestionMarkIcon />} color={"info"} />
        </Link>
      );
    case WorkerSelectorStatusEnum.NOTIFIED:
      return (
        <Link id={"job-" + job.jobId + "-findingPliny-link"} to={{ pathname: getJobRoute(job.jobId) }} state={{ requestedJob: { job } }}>
          <ThemedButton title={`Searching`} style={{ marginTop: 0 }} endIcon={<CircularProgress size={"1rem"} color={"inherit"} />} color={"warning"} />
        </Link>
      );
    case WorkerSelectorStatusEnum.NO_PLINYS:
      return <Typography color={"error"}>No plinys available</Typography>;
  }
}
