import React, { useEffect, useState } from "react";
import DownloadIcon from "@mui/icons-material/Download";
import { getSignedS3ObjectUrl } from "../../services/AwsStorageService";
import ThemedButton from "./ThemedButton";

type DownloadButtonProps = {
  objectKey: string;
  children: string;
  style?: any;
};

const DownloadButton = ({ objectKey, children, style }: DownloadButtonProps) => {
  const [signedUrl, setSignedUrl] = useState<string>();

  useEffect(() => {
    const getS3Url = async () => {
      const result = await getSignedS3ObjectUrl("docs", objectKey);
      setSignedUrl(result);
    };

    getS3Url();
  }, [objectKey]);

  return (
    <a href={signedUrl} target="_blank" rel="noreferrer" download>
      <ThemedButton title={children} color={"secondary"} endIcon={<DownloadIcon />} style={style} />
    </a>
  );
};

export default DownloadButton;
