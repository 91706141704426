import { format } from "date-fns";

export const parseTime = (inputTime: string): Date | null => {
  if (!inputTime) {
    return null;
  }

  const inputTimeSegments = inputTime.split(":");

  let dateAndInputTime = new Date();
  dateAndInputTime.setHours(parseInt(inputTimeSegments[0]), parseInt(inputTimeSegments[1]), parseInt(inputTimeSegments[2]), 0);

  return dateAndInputTime;
};

export const formatDateTimeLocal = (date: Date): string => {
  return format(date, "HH:mm:ss");
};
