/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const amplifyConfigProduction = {
  aws_project_region: "eu-west-2",
  aws_cognito_identity_pool_id: "eu-west-2:3df499c8-10d2-4222-ac63-833c8e9d1164",
  aws_cognito_region: "eu-west-2",
  aws_user_pools_id: "eu-west-2_70aGyti3J",
  aws_user_pools_web_client_id: "r83q1lgdsiqvvrpce7m776mis",
  oauth: {
    domain: "auth.admins.pliny.uk.auth.eu-west-2.amazoncognito.com",
    scope: ["email", "openid"],
    redirectSignIn: "https://admins.pliny.uk",
    redirectSignOut: "https://admins.pliny.uk/signin",
    responseType: "code",
  },
  federationTarget: "COGNITO_USER_POOLS",
  aws_cognito_username_attributes: ["EMAIL"],
  aws_cognito_social_providers: [],
  aws_cognito_signup_attributes: [],
  aws_cognito_mfa_configuration: "OFF",
  aws_cognito_mfa_types: [],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 8,
    passwordPolicyCharacters: ["REQUIRES_LOWERCASE", "REQUIRES_UPPERCASE", "REQUIRES_NUMBERS", "REQUIRES_SYMBOLS"],
  },
  aws_cognito_verification_mechanisms: ["EMAIL"],
};

export default amplifyConfigProduction;
