import axiosClient from "axios";
import { getBaseUrl, setupHeaders, setupUrlPath } from "./AwsClientHelper";

const schoolApiClient = axiosClient.create({
  baseURL: getBaseUrl(),
});

schoolApiClient.interceptors.request.use(
  async (config) => {
    try {
      config = await setupHeaders(config);
      config = await setupUrlPath(config, "/api/schools", "custom:schoolId");
    } catch (err) {
      console.log(err);
    }
    return config;
  },

  (error) => {
    return Promise.reject(error);
  }
);

export default schoolApiClient;
