import { Job, WorkerProfileForSchools } from "../../../model/ModelTypes";
import { Card, CardHeader, Grid } from "@mui/material";
import React from "react";
import StyledRating from "../../reviews/Rating.styled";
import Box from "@mui/material/Box";
import DownloadButton from "../../buttons/DownloadButton";
import { getWorkerCvKey } from "../../../services/AwsStorageService";
import { getRoleSpecificDetails } from "./CardData";
import RollingReview from "./RollingReview";
import CloseIcon from "@mui/icons-material/Close";
import { getWorkerName } from "../../../helpers/TextFormatter";
import { PlinyProfilePicture } from "../../images/PlinyProfilePicture";
import IconButton from "@mui/material/IconButton";
import SpacedText from "./SpacedText";

type RankedWorkersCardProps = {
  workerProfile: WorkerProfileForSchools;
  job: Job;
  removeWorker: (workerId: string) => void;
};

const RankedWorkerItem = ({ workerProfile, job, removeWorker }: RankedWorkersCardProps) => {
  const { worker, averageRating } = workerProfile;

  const handleRemoveWorker = () => {
    removeWorker(worker.workerId);
  };

  return (
    <Card elevation={2} className="Card" sx={{ m: 1 }}>
      <CardHeader
        action={
          <IconButton onClick={handleRemoveWorker}>
            <CloseIcon />
          </IconButton>
        }
        title={getWorkerName(worker)}
        sx={{ m: 0, pt: 0, pb: 1 }}
      />

      <Grid container spacing={2}>
        <Grid item xs={12} lg={3}>
          <PlinyProfilePicture worker={worker} size={180} />
        </Grid>

        <Grid item xs={12} lg={4}>
          <Box sx={{ display: "flex" }}>
            <SpacedText>{`Average Rating: `}</SpacedText>
            <StyledRating name={"pliny-rating"} value={averageRating} readOnly precision={0.2} />
          </Box>
          <SpacedText>{`Daily Rate: £${worker.dailyRate}`}</SpacedText>
          <SpacedText>{`${getRoleSpecificDetails(worker, job)} Specialist`}</SpacedText>
        </Grid>

        <Grid item xs={12} lg={3}>
          <RollingReview workerProfile={workerProfile} />
        </Grid>

        <Grid item xs={12} lg={1} sx={{ display: "inline" }}>
          <DownloadButton objectKey={getWorkerCvKey(worker)} style={{ marginRight: "10px" }}>
            CV
          </DownloadButton>
        </Grid>
      </Grid>
    </Card>
  );
};

export default RankedWorkerItem;
