import React, { ReactNode } from "react";
import * as IoIcons from "react-icons/io";
import ThemedButton from "./ThemedButton";

export interface SubmitButtonDetails {
  title?: string;
  onClickHandler: (event: any) => void;
  isFormValid: boolean;
  id?: string;
  endIcon?: ReactNode;
  color?: "secondary" | "error" | "success" | "warning" | "info";
}

const SubmitButton = ({ title, onClickHandler, isFormValid, color, id, endIcon }: SubmitButtonDetails) => {
  return (
    <ThemedButton
      title={title ?? "Save"}
      onClickHandler={onClickHandler}
      isFormValid={isFormValid}
      type={"submit"}
      endIcon={endIcon ?? <IoIcons.IoMdSave />}
      color={color}
      id={id}
    />
  );
};

export default SubmitButton;
