import React from "react";
import { Card, Grid } from "@mui/material";
import "./SimpleCard.css";
import { SimpleCardTitle } from "./SimpleCardTitle";

export interface SimpleCardDetails {
  title: string;
  children: any;
  subtitle?: string;
  icon?: any;
  titleDecoration?: any;
  backgroundColor?: string;
}

const SimpleCard = (details: SimpleCardDetails) => {
  return (
    <Card elevation={2} className="px-6 py-5 h-full Card" style={{ backgroundColor: details.backgroundColor ?? null }}>
      {details.titleDecoration && (
        <Grid container>
          <Grid item lg={6} md={6} sm={12} xs={12} alignItems={"start"}>
            <SimpleCardTitle title={details.title} />
          </Grid>
          <Grid item lg={6} md={6} sm={6} xs={12}>
            {details.titleDecoration}
          </Grid>
        </Grid>
      )}
      {!details.titleDecoration && <SimpleCardTitle title={details.title} />}

      {details.subtitle && <div className="card-subtitle mb-4">{details.subtitle}</div>}
      {details.children}
    </Card>
  );
};

export default SimpleCard;
