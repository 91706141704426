import { Grid, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import theme from "../../constants/Theme";
import { Job, PrimaryTeacherJob, RoleType, SecondaryTeacherJob } from "../../model/ModelTypes";
import schoolApiClient from "../../services/SchoolApiClient";
import SubmitButton from "../buttons/SubmitButton";
import SimpleCard from "../SimpleCard";
import RatePliny from "./RatePliny";

const ReviewPlinyCard = (props: { job: Job }) => {
  const [showSuccessMessage, setShowSuccessMessage] = useState<boolean>(false);

  const [workerRating, setWorkerRating] = useState<number>(3);
  const [workerReview, setWorkerReview] = useState<number>();

  const job = props.job;

  const handleChange = (event: any) => {
    event.persist();
    setWorkerReview(event.target.value);
  };

  const formSubmitHandler = (event: any) => {
    event.preventDefault();

    const jobUpdateParams = {
      workerRating: workerRating,
      workerReview: workerReview,
      jobId: job.jobId,
    };

    schoolApiClient.post(`/workers/${worker.workerId}/rate`, jobUpdateParams).then(() => {
      setShowSuccessMessage(true);
    });
  };

  const updateRating = (event: any) => {
    setWorkerRating(event);
  };

  const worker = job.worker;

  return (
    <SimpleCard key={"card-job-" + job.jobId} title={`Review ${worker?.forename} ${worker?.surname}`}>
      <Typography>{`From ${job.startDate} until ${job.endDate}`}</Typography>
      <Typography>{job.roleType === RoleType.PRIMARY_TEACHER ? (job as PrimaryTeacherJob).primaryYear : (job as SecondaryTeacherJob).subject.name}</Typography>
      <Grid container spacing={6}>
        <Grid item xs={12} lg={6}>
          <RatePliny id={"workerRating-job-" + job.jobId} onChangeValue={updateRating} />
          <TextField
            className="mb-4 w-full"
            label="Pliny Review"
            onChange={handleChange}
            type="text"
            name="workerReview"
            id={"workerReview-job-" + job.jobId}
            value={workerReview || ""}
            fullWidth
            multiline
          />
        </Grid>
      </Grid>

      {showSuccessMessage && <Typography color={theme.palette.success.main}>Review successfully saved!</Typography>}

      <SubmitButton title={"Save"} id={`save-review-${job.jobId}`} onClickHandler={formSubmitHandler} isFormValid={!showSuccessMessage} />
    </SimpleCard>
  );
};

export default ReviewPlinyCard;
