import { SecondaryTeacher } from "../../../model/ModelTypes";
import { listSpecialistSubjects } from "../../../helpers/SubjectLister";
import SimpleCard from "../../SimpleCard";
import { Table, TableBody } from "@mui/material";
import PlinyDetailsTableRow from "../PlinyDetailsTableRow";

type SecondaryTeacherProps = {
  secondaryTeacher: SecondaryTeacher;
};
const SecondaryTeacherCard = ({ secondaryTeacher }: SecondaryTeacherProps) => (
  <SimpleCard title={"Secondary Teacher Details"}>
    <Table className="whitespace-pre">
      <TableBody>
        <PlinyDetailsTableRow label={"Teacher Reference Number"} value={secondaryTeacher.teacher?.teacherReferenceNumber} />
        <PlinyDetailsTableRow label={"Is Qualified Teacher"} value={`${secondaryTeacher.teacher?.isQualifiedTeacher}`} />
        <PlinyDetailsTableRow label={"Specialist Subjects"} value={listSpecialistSubjects(secondaryTeacher.specialistSubjects)} />
      </TableBody>
    </Table>
  </SimpleCard>
);

export default SecondaryTeacherCard;
