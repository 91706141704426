import { Table, TableBody } from "@mui/material";
import React from "react";
import { calculateBusinessDays, formatDate } from "../../helpers/DateFormatter";
import { Job, roleTypeToCapitalisedString } from "../../model/ModelTypes";
import SimpleCard from "../SimpleCard";
import DetailsRow from "../tables/DetailsRow";
import JobRoleSpecificDetails from "./JobRoletypeSpecificDetails/JobRoleSpecificDetails";

export const JobDetails = (props: { job: Job }) => {
  const job = props.job;

  return (
    <SimpleCard title="Job Details">
      <Table className="whitespace-pre">
        <TableBody>
          <DetailsRow label={"Role"} value={roleTypeToCapitalisedString(job.roleType)} />
          <DetailsRow label={"Start Date"} value={formatDate(job.startDate)} />
          <DetailsRow label={"End Date"} value={formatDate(job.endDate)} />
          <DetailsRow label={"Number of Days"} value={calculateBusinessDays(job.startDate, job.endDate)} />
          <DetailsRow label={"Type"} value={job.shortOrLongTermSupply === "LONG" ? "Long Term Supply" : "Short Term Supply"} />
          <JobRoleSpecificDetails job={job} />
        </TableBody>
      </Table>
    </SimpleCard>
  );
};
