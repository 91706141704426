import theme from "../../../constants/Theme";
import { bronzeColor, goldColor, silverColor } from "../../../constants/Colors";
import { BsFillPersonFill, BsPeopleFill } from "react-icons/bs";
import { IoHourglassOutline } from "react-icons/io5";
import React from "react";
import { notifiedWorkerIconSize } from "../WorkerRequestProcessing";
import { WorkersNotified } from "./types";
import WorkerNotifiedCup from "./WorkerNotifiedCup";
import WorkerNotifiedTextIcon from "./WorkersNotifiedTextIcon";
import { Job } from "../../../model/ModelTypes";
import WorkersNotifiedListItem from "./WorkersNotifiedListItem";
import { ImCross } from "react-icons/im";
import { Link } from "react-router-dom";
import { Typography } from "@mui/material";
import PlinyAcceptedJobLink from "../../links/PlinyAcceptedJobLink";

export const WorkersNotifiedList = (props: { workerSelectorStateMessage: WorkersNotified | undefined; job: Job }) => {
  const workerSelectorStateMessage = props?.workerSelectorStateMessage;

  if (workerSelectorStateMessage) {
    // Use websocket
    return messageList(workerSelectorStateMessage?.messageType);
  } else {
    // Use https request
    return showNumberOfWorkersNotified(props.job.notifiedWorkers?.length);
  }

  function messageList(messageType: string) {
    const worker = props?.workerSelectorStateMessage?.worker;

    switch (messageType) {
      case "WorkersNotifiedMessage":
        const numberOfWorkersNotified = props?.workerSelectorStateMessage?.numberOfWorkersNotified;
        return showNumberOfWorkersNotified(numberOfWorkersNotified);

      case "WorkerAcceptedJobMessage":
        if (worker) {
          return <PlinyAcceptedJobLink worker={worker} />;
        }
        break;

      case "WorkerNotAvailableForJobMessage":
        if (worker) {
          return (
            <WorkersNotifiedListItem>
              <ImCross color={theme.palette.error.main} size={notifiedWorkerIconSize} />
              <Typography display="inline" sx={{ paddingLeft: 3 }}>{`${worker?.forename} ${worker?.surname} is unavailable.`}</Typography>
              <Link id={"requestNewPliny-link"} className={"blue-link secondaryColor"} to={{ pathname: "/request-pliny" }} style={{ paddingLeft: 5 }}>
                {`Please request a new Pliny`}
              </Link>
            </WorkersNotifiedListItem>
          );
        }
    }

    return <></>;
  }

  function showNumberOfWorkersNotified(numberOfWorkersNotified: number | undefined) {
    let chosenWorkersDisplay = [];
    if (!numberOfWorkersNotified) {
      return <></>;
    }
    if (numberOfWorkersNotified >= 1) {
      chosenWorkersDisplay.push(<WorkerNotifiedCup key={1} cupColor={goldColor} text={"Waiting on 1 Pliny"} />);
    }
    if (numberOfWorkersNotified >= 2) {
      chosenWorkersDisplay.push(<WorkerNotifiedCup key={2} cupColor={silverColor} text={"Waiting on 2 Plinys"} />);
    }
    if (numberOfWorkersNotified >= 3) {
      chosenWorkersDisplay.push(<WorkerNotifiedCup key={3} cupColor={bronzeColor} text={"Waiting on 3 Plinys"} />);
    }
    if (numberOfWorkersNotified >= 5) {
      chosenWorkersDisplay.push(
        <WorkerNotifiedTextIcon key={5} text={"Waiting on 5 Plinys"}>
          <BsFillPersonFill size={notifiedWorkerIconSize} />
        </WorkerNotifiedTextIcon>
      );
    }
    if (numberOfWorkersNotified >= 10) {
      chosenWorkersDisplay.push(
        <WorkerNotifiedTextIcon key={10} text={"Waiting on 10 Plinys"}>
          <BsPeopleFill size={notifiedWorkerIconSize} />
        </WorkerNotifiedTextIcon>
      );
    }
    if (numberOfWorkersNotified > 10) {
      chosenWorkersDisplay.push(
        <WorkerNotifiedTextIcon key={20} text={"All available plinys have been notified"}>
          <IoHourglassOutline size={notifiedWorkerIconSize} />
        </WorkerNotifiedTextIcon>
      );
    }
    return <>{chosenWorkersDisplay}</>;
  }
};
