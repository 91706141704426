import { useForm } from "react-hook-form";
import { getTodaysDateAtMidnight, getTomorrowsDateAtMidnight } from "../../../helpers/DateFormatter";

const todaysDate: Date = getTodaysDateAtMidnight();

const getInitialDates = () => {
  const currentTime = new Date().getHours();
  if (currentTime < 15) {
    return todaysDate;
  }
  return getTomorrowsDateAtMidnight();
};

const useNewJobForm = () => {
  // TODO-CI fix this any
  const form = useForm<any>({
    mode: "onChange",
    defaultValues: {
      startDate: getInitialDates(),
      endDate: getInitialDates(),
      isLongTermSupply: undefined,
      specificWorker: undefined,
      halfDay: null,
      requiresQualifiedTeacher: undefined,
    },
  });

  const { reset } = form;

  const { control, formState } = form;
  const { errors } = formState;

  return { form, control, errors, reset };
};

export default useNewJobForm;
