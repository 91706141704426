import { InternalAxiosRequestConfig } from "axios";
import { Auth } from "aws-amplify";

export const getBaseUrl = () => {
  return process.env.REACT_APP_BACKEND_API_URL;
};

export const setupHeaders = async (config: InternalAxiosRequestConfig) => {
  const awsSession = await Auth.currentSession();

  config.headers.Authorization = awsSession.getAccessToken().getJwtToken();
  config.headers.IdToken = awsSession.getIdToken().getJwtToken();

  return config;
};

export const setupUrlPath = async (config: InternalAxiosRequestConfig, baseUrl: string, attributeName: string) => {
  const userInfo = await Auth.currentUserInfo();
  const attributeValue = userInfo.attributes[attributeName];

  config.baseURL += `${baseUrl}/${attributeValue}`;

  return config;
};
