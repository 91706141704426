export type SchoolSettings = {
  id: number;
  maxShortTermDailyRate: number;
  startTime: string;
  endTime: string;
};
export type Subject = {
  code: string;
  name: string;
};
export type School = {
  schoolId: number;
  name: string;
  phoneNumber: string;
  email: string;

  addressLine1: string;
  addressLine2: string;
  townCity: string;
  postcode: string;

  schoolType: SchoolType;

  schoolSettings: SchoolSettings;
  schoolAppInfo: SchoolAppInfo;
};
type SchoolAppInfo = {
  schoolId: number;
  profilePictureFileName?: string;
};

export type Admin = {
  adminId: number;
  email: string;
  forename: string;
  surname: string;
  school: School;
};
export type Job = {
  jobId: number;
  schoolDailyRate: number;
  startDate: Date;
  endDate: Date;
  workerRating: number;
  workerReview?: string;
  workerDidNotAttend?: boolean;
  schoolRating?: number;
  schoolReview?: string;
  school?: School;
  worker?: Worker;
  rankedWorkersRequested: boolean;
  workerSelectorRunning: boolean;
  noWorkersAvailable: boolean;
  isCancelled: boolean;
  notifiedWorkers: Worker[];
  shortOrLongTermSupply?: ShortOrLongTermSupply;
  roleType: RoleType;
};
export type PrimaryTeacherJob = Job & {
  roleType: RoleType.PRIMARY_TEACHER;
  primaryYear: PrimaryYear;
};
export type SecondaryTeacherJob = Job & {
  roleType: RoleType.SECONDARY_TEACHER;
  subject: Subject;
};
export type Worker = {
  workerId: string;
  approved: boolean;
  email: string;
  forename: string;
  surname: string;
  addressLine1: string;
  addressLine2: string;
  townCity: string;
  postcode: string;
  phoneNumber: string;
  dob: string;
  specialistSubjects: Subject[];
  expoToken?: string;
  profilePictureUrl?: string;
  dbsNumber?: string;
  workerReferenceNumber?: string;
  dailyRate?: number;
  interviewScore?: number;
  roles?: Role[];
  workerAppInfo?: AppInfo;
};

export type RequestedJob = {
  job: Job;
  rankedWorkers: WorkerProfileForSchools[];
};

export type AppInfo = {
  workerId: number;
  profilePictureFileName?: string;
  cvFileName?: string;
  proofOfAddressFileName?: string;
  proofOfIdFileName?: string;
  hasSeenApprovedWelcomeScreen?: boolean;
  initiatedAccountDeletion?: boolean;
  initiatedAccountDeletionDateUpdated?: Date;
  pausedAccount?: boolean;
  pausedAccountDateUpdated?: Date;
};

export type WorkerCompliance = {
  workerId: string;
  childcareDisqualificationDateUpdated: Date;
  childcareDisqualificationNotes: string;
  childcareDisqualificationState: "PROCESSING" | "NOT_APPLICABLE" | "PASSED" | "FAILED";
  childrensBarredListDateUpdated: Date;
  childrensBarredListNotes: string;
  childrensBarredListState: "PROCESSING" | "NOT_CHECKED" | "CHECKED_AND_DBS_NUMBER_RECEIVED";
  contractDateUpdated: Date;
  contractNotes: string;
  contractState: "PROCESSING" | "SIGNED";
  dbsDateUpdated: Date;
  dbsInitialCheckDate: Date;
  dbsNotes: string;
  dbsState:
    | "PROCESSING"
    | "UPDATE_SERVICE_CHECK_REQUIRED"
    | "UPDATE_SERVICE_CHECKED"
    | "DBS_APPLICATION_REQUIRED"
    | "DBS_APPLICATION_REQUESTED"
    | "DBS_APPLICATION_CHECKED";
  fitnessToTeachDateUpdated: Date;
  fitnessToTeachNotes: string;
  fitnessToTeachState: "PROCESSING" | "NO_CONFIRMATION_RECEIVED" | "NOT_FIT_TO_TEACH" | "FIT_TO_TEACH";
  identityCheckDateUpdated: Date;
  identityCheckNotes: string;
  identityCheckState: "PROCESSING" | "SUCCESSFUL" | "UNSUCCESSFUL";
  interviewDateUpdated: Date;
  interviewNotes: string;
  interviewState: "PROCESSING" | "PASSED" | "FAILED";
  keepingChildrenSafeDateUpdated: Date;
  keepingChildrenSafeNotes: string;
  keepingChildrenSafeState: "PROCESSING" | "STATUTORY_GUIDANCE_NOT_READ" | "STATUTORY_GUIDANCE_READ_AND_SIGNED";
  letterProfessionalStandingDateUpdated: Date;
  letterProfessionalStandingNotes: string;
  letterProfessionalStandingState: "PROCESSING" | "NOT_APPLICABLE" | "APPLICABLE_BUT_NO_LETTER_RECEIVED" | "APPLICABLE_AND_LETTER_RECEIVED";
  overseasClearanceDateUpdated: Date;
  overseasClearanceNotes: string;
  overseasClearanceState: "PROCESSING" | "NOT_APPLICABLE" | "LETTER_RECEIVED" | "ALL_CLEAR_RECEIVED_FROM_FOREIGN_COUNTRY";
  qualificationCheckDateUpdated: Date;
  qualificationCheckNotes: string;
  qualificationCheckState: "PROCESSING" | "SUCCESSFUL" | "UNSUCCESSFUL";
  referencesDateUpdated: Date;
  referencesNotes: string;
  referencesState: "PROCESSING" | "NO_REFERENCES_RECEIVED" | "ONE_REFERENCE_RECEIVED" | "TWO_REFERENCES_RECEIVED";
  rightToWorkDateUpdated: Date;
  rightToWorkNotes: string;
  rightToWorkState: "PROCESSING" | "SUCCESSFUL" | "UNSUCCESSFUL";
  safeguardingTrainingDateUpdated: Date;
  safeguardingTrainingNotes: string;
  safeguardingTrainingState: "PROCESSING" | "SAFEGUARDING_CERTIFICATE_RECEIVED" | "SAFEGUARDING_CERTIFICATE_NOT_RECEIVED";
  signedRehabOffendersActDateUpdated: Date;
  signedRehabOffendersActNotes: string;
  signedRehabOffendersActState: "PROCESSING" | "DECLARATION_RECEIVED_NO_PREVIOUS_CONVICTIONS" | "DECLARATION_RECEIVED_PREVIOUS_CONVICTIONS";
  workerReferenceNumberDateUpdated: Date;
  workerReferenceNumberNotes: string;
  workerReferenceNumberState: "PROCESSING" | "WORKER_REFERENCE_NUMBER_RECEIVED" | "WORKER_REFERENCE_NUMBER_NOT_RECEIVED" | "SUCCESSFUL_INITIAL_CHECK";
};

export type WorkerReviews = {
  review: string;
  schoolName: string;
  subjectName: string;
  reviewDate: string;
};
export type WorkerProfileForSchools = {
  worker: Worker;
  averageRating: number;
  reviews: Array<WorkerReviews>;
};

export enum HalfDay {
  FULL_DAY = "FULL_DAY",
  AM = "AM",
  PM = "PM",
}

export enum ShortOrLongTermSupply {
  LONG = "LONG",
  SHORT = "SHORT",
}
type AbstractRole = {
  roleType: RoleType;
  roleId?: number;
};

export type Teacher = {
  teacherId: number;
  isQualifiedTeacher?: boolean;
  teacherReferenceNumber?: string;
};

export type SecondaryTeacher = AbstractRole & {
  roleType: RoleType.SECONDARY_TEACHER;
  specialistSubjects?: Subject[];
  teacher?: Teacher;
};

export type PrimaryTeacher = AbstractRole & {
  roleType: RoleType.PRIMARY_TEACHER;
  primaryExperienceYears?: PrimaryExperienceYears[];
  isPhonicsTrained?: boolean;
  teacher?: Teacher;
};

export type Role = SecondaryTeacher | PrimaryTeacher;

export enum PrimaryExperienceYears {
  EARLY_YEARS = "EARLY_YEARS",
  KS1 = "KS1",
  LKS2 = "LKS2",
  UKS2 = "UKS2",
}

export enum PrimaryYear {
  RECEPTION = "RECEPTION",
  Y1 = "Y1",
  Y2 = "Y2",
  Y3 = "Y3",
  Y4 = "Y4",
  Y5 = "Y5",
  Y6 = "Y6",
}

export enum RoleType {
  PRIMARY_TEACHER = "PRIMARY_TEACHER",
  SECONDARY_TEACHER = "SECONDARY_TEACHER",
}

export enum SchoolType {
  PRIMARY = "PRIMARY",
  SECONDARY = "SECONDARY",
}

const isValidSchoolType = (value: string): boolean => {
  return Object.values(SchoolType).includes(value as SchoolType);
};

export const roleTypesAsList = (schoolType: SchoolType): RoleType[] => {
  if (!isValidSchoolType(schoolType)) {
    throw new Error("Invalid school type");
  }

  let roles: RoleType[] = Object.values(RoleType);
  roles = roles.filter((role) => role.match(schoolType));
  return roles;
};

export const roleTypeToCapitalisedString = (roleType: RoleType) => {
  let roleString = RoleType[roleType].replace("_", " ").toLowerCase();
  roleString = roleString.charAt(0).toUpperCase() + roleString.slice(1);
  return roleString;
};
