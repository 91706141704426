import SimpleCard from "../../SimpleCard";
import { Table, TableBody } from "@mui/material";
import React from "react";
import { PrimaryTeacher } from "../../../model/ModelTypes";
import PlinyDetailsTableRow from "../PlinyDetailsTableRow";

type PrimaryTeacherProps = {
  primaryTeacher: PrimaryTeacher;
};
const PrimaryTeacherCard = ({ primaryTeacher }: PrimaryTeacherProps) => {
  return (
    <SimpleCard title={"Primary Teacher Details"}>
      <Table className="whitespace-pre">
        <TableBody>
          <PlinyDetailsTableRow label={"Teacher Reference Number"} value={primaryTeacher.teacher?.teacherReferenceNumber} />
          <PlinyDetailsTableRow label={"Is Qualified Teacher"} value={`${primaryTeacher.teacher?.isQualifiedTeacher}`} />
        </TableBody>
      </Table>
    </SimpleCard>
  );
};

export default PrimaryTeacherCard;
