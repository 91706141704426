import { GiTrophyCup } from "react-icons/gi";
import React from "react";
import { notifiedWorkerIconSize } from "../WorkerRequestProcessing";
import WorkerNotifiedTextIcon from "./WorkersNotifiedTextIcon";

export default function WorkerNotifiedCup(props: { cupColor: string; text: string }) {
  return (
    <WorkerNotifiedTextIcon text={props.text} key={`worker-notified-cup-${props.text}`}>
      <GiTrophyCup color={props.cupColor} size={notifiedWorkerIconSize} />
    </WorkerNotifiedTextIcon>
  );
}
