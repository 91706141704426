import WorkersNotifiedListItem from "../jobadded/workersnotifiedlist/WorkersNotifiedListItem";
import { ImCheckmark } from "react-icons/im";
import theme from "../../constants/Theme";
import { notifiedWorkerIconSize } from "../jobadded/WorkerRequestProcessing";
import PlinyDetailsLinkButton from "../plinys/PlinyDetailsLinkButton";
import React from "react";
import { Worker } from "../../model/ModelTypes";
import { getShortWorkerName } from "../../helpers/TextFormatter";

type PlinyAcceptedJobLinkProps = Readonly<{
  worker: Worker;
}>;

const PlinyAcceptedJobLink = ({ worker }: PlinyAcceptedJobLinkProps) => (
  <WorkersNotifiedListItem>
    <ImCheckmark color={theme.palette.success.main} size={notifiedWorkerIconSize} style={{ marginRight: 10 }} />
    <PlinyDetailsLinkButton worker={worker} title={`${getShortWorkerName(worker)} has accepted!`} />
  </WorkersNotifiedListItem>
);

export default PlinyAcceptedJobLink;
