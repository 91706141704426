import React, { useContext } from "react";
import SimpleCard from "../../components/SimpleCard";
import { PageLoadingSpinner } from "../../components/nav/PageLoadingSpinner";
import { UserContext } from "../../context/UserContext";
import { AppContext } from "../../context/AppContext";
import { SchoolType } from "../../model/ModelTypes";
import RequestSecondaryTeacherForm from "../../components/jobs/forms/RequestSecondaryTeacherForm";
import RequestPrimaryTeacherForm from "../../components/jobs/forms/RequestPrimaryTeacherForm";
import { PageLoadError } from "../../components/nav/PageLoadError";

const RequestAPlinyPage = () => {
  const userContext = useContext(UserContext);
  const appContext = useContext(AppContext);

  if (!(userContext.admin?.school.schoolId && appContext.defaultSubjects)) {
    return (
      <SimpleCard title={"Request a Pliny"}>
        <PageLoadingSpinner />
      </SimpleCard>
    );
  }
  if (userContext.admin?.school.schoolType === SchoolType.PRIMARY) {
    return (
      <div className="m-sm-30">
        <RequestPrimaryTeacherForm />
      </div>
    );
  }
  if (userContext.admin?.school.schoolType === SchoolType.SECONDARY) {
    return (
      <div className="m-sm-30">
        <RequestSecondaryTeacherForm />
      </div>
    );
  }

  return <PageLoadError error={"Sorry there is some sort of data issue"} />;
};

export default RequestAPlinyPage;
