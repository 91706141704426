import { ReactNode } from "react";
import { Button } from "@mui/material";
import * as React from "react";

type ThemedButtonProps = {
  title: string;
  onClickHandler?: (event: any) => void;
  isFormValid?: boolean;
  id?: string;
  endIcon?: ReactNode;
  color?: "secondary" | "error" | "success" | "warning" | "info";
  variant?: "outlined";
  type?: "submit";
  style?: React.CSSProperties;
};

const ThemedButton = ({ id, endIcon, title, onClickHandler, isFormValid, color, variant, type, style }: ThemedButtonProps) => {
  return (
    <Button
      variant={variant ?? "contained"}
      type={type}
      id={id}
      color={color ?? "primary"}
      endIcon={endIcon}
      onClick={onClickHandler}
      disabled={isFormValid === false}
      style={style}
    >
      {title}
    </Button>
  );
};

export default ThemedButton;
